import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../src/Redux/loginSlice";
import userReducer from "../src/Redux/userSlice";
import ServiceReducer from "../src/Redux/serviceSlice";
import serviceTypeReducer from "../src/Redux/serviceTypeSlice";
import subServiceTypeReducer from "../src/Redux/subServiceTypeSlice";
import scheduleMeetingReducer from "../src/Redux/scheduleMeeting";
import clientFormReducer from "../src/Redux/clientFormSlice";
import expertiseReducer from "../src/Redux/expertiseSlice";
import uploadFileReducer from "../src/Redux/uploadSlice";
import dashboardReducer from "../src/Redux/Dashboard"
import faqReducer from "../src/Redux/faqSlice"
import emailReducer from "../src/Redux/emailSlice"
import reportReducer from "../src/Redux/reportSlice"

export default configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    service: ServiceReducer,
    cleint: clientFormReducer,
    serviceType: serviceTypeReducer,
    subServiceType: subServiceTypeReducer,
    scheduleMeeting: scheduleMeetingReducer,
    expertise: expertiseReducer,
    uploadFile: uploadFileReducer,
    dashboard: dashboardReducer,
    faq: faqReducer,
    email: emailReducer,
    report: reportReducer
  },
});
