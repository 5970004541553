//To concate the path for the public folder
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

/**
 * extract error message
 * @param {*} err error object
 * @returns string
 */
export const getErrorMessage = err => {
  console.log(err)
  return err.message || 'Oops! something went wrong.'
}