// import { doLogout } from "../actions/auth";
import axios from "axios";
import STORAGE_KEYS from "./keys";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const axiosInterceptor = () => {
  const token = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    ? localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    : document?.cookie?.split?.(";")?.[0]?.split?.("=")?.[1];

  //console.log(token);
  axios.defaults.headers.common["Authorization"] = token ? `${token}` : null;

  axios.interceptors.request.use(null, (error) => {
    console.log(error);
    if (error.response) {
      if (error.response?.status === 401) {
        return Promise.reject(error);
      } else return Promise.reject(error);
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: "Something went wrong,Please try again later!!!",
          },
        },
      };
      return Promise.reject(err);
    }
  });
                                                                                                                
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error.response?.status);
      if (error.response) {
        if (error.response?.status === 401) {
          localStorage.clear();
          history.replace("/login");
          return Promise.reject(error);
        } else return Promise.reject(error);
      } else if (error.request) {
        let err = {
          response: {
            data: {
              message: "Something went wrong,Please try again later!!!",
            },
          },
        };
        return Promise.reject(err);
      }
    }
  );
};
