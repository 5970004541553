import {
  Button,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../../components/common/Breadcrumb";
import { API_BASE } from "../../../../../constants/api";

function AddServiceType(prop) {
  const [form] = Form.useForm();

  const [subServiceType, setSubServiceType] = useState();
  const [serviceType, setServiceType] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageType(prop.pageType);
  }, [prop]);

  //getting serviceType
  useEffect(() => {
    if (pageType === "edit") {
      axios
        .get(
          `${API_BASE}v1/service-type/getServiceTypeById/${location.state.id}`
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            setServiceType(res.data.data);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pageType]);

  //setting Form
  useEffect(() => {
    if (serviceType && pageType === "edit") {
      const selectSubServices =
        serviceType?.subService?.map((data) => data._id) || [];
      form.setFieldsValue({
        title: serviceType.title,
        isActive: serviceType.isActive,
        selectedSubServiceIDS: selectSubServices,
      });
    }
  }, [serviceType]);

  //All Sub-service Type List
  useEffect(() => {
    const data = { limit: 1000 };
    setIsLoading(true);
    if (pageType === "add" || (pageType === "edit" && serviceType))
      axios
        .get(`${API_BASE}v1/sub-service-type/getAllSubServiceType`, data)
        .then((res) => {
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            let tarray = res.data.data.subServiceTypeList.map((data, index) => {
              return { ...data, key: index };
            });
            if (serviceType) {
              tarray = [...tarray, ...serviceType?.subService];
            }

            setSubServiceType(tarray);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
  }, [serviceType, pageType]);

  const onFinish = (values) => {
    if (!serviceType)
      axios
        .post(`${API_BASE}v1/service-type/addServiceType`, values)
        .then((res) => {
          if (res.data.statusCode === 200) {
            message.success(res.data.message);
            navigate("/data/service_type");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    else {
      let data = { ...values, serviceTypeId: location.state.id };

      axios
        .post(`${API_BASE}v1/service-type/updateServiceType`, data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            message.success(res.data.message);
            navigate("/data/service_type");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Layout className="site-layout-background">
          <Content className="admin-webpanel-content">
            {/* <Breadcrumbs content="Add Service Type" /> */}

            <div className="filter-wrap  event-wrap">
              <div>
                <h2 className="title">Service Type</h2>
              </div>
            </div>

            <div className="remove-wrap">
              <div className="event-wrap">
                <Form
                  className="sectionadd-form"
                  form={form}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    isActive: true,
                  }}
                >
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">Service Type Name</label>
                        <Form.Item
                          name="title"
                          rules={[
                            {
                              whitespace: true,
                              message: "name can't be empty!",
                            },
                            {
                              required: true,
                              message: "Please enter service type name!",
                            },
                            () => ({
                              validator(_, value) {
                                // if (value && value.search(/^[a-zA-Z]+$/) === -1) {
                                //   return Promise.reject("Provide valid name");
                                // }
                                if (
                                  value &&
                                  (value.length < 2 || value.length > 50)
                                ) {
                                  return Promise.reject(
                                    "name must contain at least 2 character"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Service Type Name" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">
                          Status (Inactive/Active)
                        </label>
                        <Form.Item name="isActive" valuePropName="checked">
                          <Switch />
                        </Form.Item>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">Sub Service Type</label>
                        <Form.Item
                          name="selectedSubServiceIDS"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (subServiceType.length === 0) {
                                  return Promise.reject(
                                    "There is no Sub ServiceType to link, Please Add new Sub ServiceType"
                                  );
                                }
                                if (
                                  subServiceType.length &&
                                  (value?.length === 0 || !value)
                                ) {
                                  return Promise.reject(
                                    "Please Select Sub ServiceType"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please Select Sub Service Type"
                          >
                            {subServiceType?.map((data) => (
                              <Select.Option key={data._id} value={data._id}>
                                {data.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12 mt-4">
                      <div className="view-btn">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-primary"
                        >
                          Save
                        </Button>
                        <Button type="secondary" className="btn-secondary">
                          <Link to="/data/service_type">Cancel</Link>
                        </Button>
                      </div>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </Content>
        </Layout>
      )}
    </>
  );
}

export default AddServiceType;
