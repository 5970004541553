import React, { useEffect, useState } from "react";
import { Form, Input, Select, Row, Radio, message } from "antd";
import Password from "antd/lib/input/Password";
import axios from "axios";
import { API_BASE } from "../../../constants/api";
import {
  fillClientForm,
  getClientDetail,
} from "../../../Redux/clientFormSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const IT = (prop) => {
  const [form] = Form.useForm();

  const [checkListId, setCheckListId] = useState();

  const [checkListId1, setCheckListId1] = useState();
  const [iTCheckList, setITCheckList] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      //setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          //console.log(res.payload.data.ITChecklistID);
          setCheckListId1(res.payload.data.ITChecklistID);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  //getting IT checklist
  useEffect(() => {
    //console.log(checkListId1);
    if (checkListId1) {
      axios
        .get(`${API_BASE}v1/ITChecklist/getITChecklistById/${checkListId1}`)
        .then((res) => {
          if (res.data.statusCode === 200) {
            //console.log(res.data.data);
            setITCheckList(res.data.data);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [checkListId1]);

  //Setting Form
  useEffect(() => {
    //console.log(iTCheckList);
    if (iTCheckList) {
      form.setFieldsValue({
        companyName: iTCheckList?.companyName,
        companyWebsite: iTCheckList?.companyWebsite,
        ITPerson: iTCheckList?.ITPerson,
        ITPersonDetails: iTCheckList?.ITPersonDetails,
        ITGuy: iTCheckList?.ITGuy,
        iAgree: iTCheckList?.iAgree,

        customerHavingVPN:
          iTCheckList?.siteConnectivity?.hardwareBased?.customerHavingVPN,
        ssiBasedVPN: iTCheckList?.siteConnectivity?.hardwareBased?.ssiBasedVPN,
        staticsRemoteDesktop:
          iTCheckList?.siteConnectivity?.hardwareBased?.staticsRemoteDesktop,

        userName1:
          iTCheckList?.siteConnectivity?.softwareBasedRDPSlapshot?.userName,
        passWord1:
          iTCheckList?.siteConnectivity?.softwareBasedRDPSlapshot?.passWord,

        ip: iTCheckList?.siteConnectivity?.microsoftRDP?.ip,
        portNumber: iTCheckList?.siteConnectivity?.microsoftRDP?.portNumber,

        email: iTCheckList?.siteConnectivity?.citrixWorkspace?.email,
        credentials:
          iTCheckList?.siteConnectivity?.citrixWorkspace?.credentials,

        awesun: iTCheckList?.siteConnectivity?.awesun,
        anyDesk: iTCheckList?.siteConnectivity?.anyDesk,
        other: iTCheckList?.siteConnectivity?.other,

        oneDrive: iTCheckList?.dataExchangeDetails?.oneDrive,
        dropBox: iTCheckList?.dataExchangeDetails?.dropBox,
        googleDrive: iTCheckList?.dataExchangeDetails?.googleDrive,
        citrixShareFile: iTCheckList?.dataExchangeDetails?.citrixShareFile,
        others: iTCheckList?.dataExchangeDetails?.others,

        clientOSDetails: iTCheckList?.endPointDetails?.clientOSDetails,
        networkType: iTCheckList?.endPointDetails?.networkType,
        userName3: iTCheckList?.endPointDetails?.userName,
        passWord3: iTCheckList?.endPointDetails?.passWord,
        windowsFirewall: iTCheckList?.endPointDetails?.windowsFirewall,
        clientAntiVirus: iTCheckList?.endPointDetails?.clientAntiVirus,
        IsRDPWorking: iTCheckList?.endPointDetails?.IsRDPWorking,
        needVPNClient: iTCheckList?.endPointDetails?.needVPNClient,
        needRemoteDesktop: iTCheckList?.endPointDetails?.needRemoteDesktop,
        configDone: iTCheckList?.endPointDetails?.configDone,
        needToRestart: iTCheckList?.endPointDetails?.needToRestart,

        // finalSolution: {
        //   actionPlanOne: iTCheckList.finalSolution.actionPlanOne,
        //   actionPlanTwo: iTCheckList.finalSolution.actionPlanTwo,
        //   actionPlanThree: iTCheckList.finalSolution.actionPlanThree,
        // },

        details1: iTCheckList?.finalSolution?.actionPlanOne?.details,
        remarks1: iTCheckList?.finalSolution?.actionPlanOne?.remarks,

        details2: iTCheckList?.finalSolution?.actionPlanTwo?.details,
        remarks2: iTCheckList?.finalSolution?.actionPlanTwo?.remarks,

        details3: iTCheckList?.finalSolution?.actionPlanThree?.details,
        remarks3: iTCheckList?.finalSolution?.actionPlanThree?.remarks,

        moptions: iTCheckList?.softwareApplication?.managementSoftware?.options,
        mothers: iTCheckList?.softwareApplication?.managementSoftware?.others,

        accountingSftware: iTCheckList?.softwareApplication?.accountingSftware,

        toptions: iTCheckList?.softwareApplication?.taxPrepSoftware?.options,
        tothers: iTCheckList?.softwareApplication?.taxPrepSoftware?.others,

        timeTrackSoftware: iTCheckList?.softwareApplication?.timeTrackSoftware,

        ioptions:
          iTCheckList?.softwareApplication?.internalCommSoftware?.options,
        iothers: iTCheckList?.softwareApplication?.internalCommSoftware?.others,

        clientCommSoftware:
          iTCheckList?.softwareApplication?.clientCommSoftware,
        passwordSoftware: iTCheckList?.softwareApplication?.clientCommSoftware,
      });
    }
  }, [iTCheckList]);

  const onFinish = (values) => {
    //console.log(values);
    const data = {
      ...values,
      //   companyName: "string",
      //   companyWebsite: "string",
      //   ITPerson: "string",
      //   ITPersonDetails: "string",
      //   ITGuy: 2,
      //values,
      siteConnectivity: {
        hardwareBased: {
          customerHavingVPN: values.customerHavingVPN,
          ssiBasedVPN: values.ssiBasedVPN,
          staticsRemoteDesktop: values.staticsRemoteDesktop,
        },
        softwareBasedRDPSlapshot: {
          userName: values.userName1,
          passWord: values.passWord1,
        },
        microsoftRDP: {
          ip: values.ip,
          portNumber: values.portNumber,
        },
        citrixWorkspace: {
          email: values.email,
          credentials: values.credentials,
        },
        awesun: values.awesun,
        anyDesk: values.anyDesk,
        other: values.other,
      },

      dataExchangeDetails: {
        oneDrive: values.oneDrive,
        dropBox: values.dropBox,
        googleDrive: values.googleDrive,
        citrixShareFile: values.citrixShareFile,
        others: values.others,
      },

      endPointDetails: {
        clientOSDetails: values.clientOSDetails,
        networkType: values.networkType,
        userName: values.userName3,
        passWord: values.passWord3,
        windowsFirewall: values.windowsFirewall,
        clientAntiVirus: values.clientAntiVirus,
        IsRDPWorking: values.IsRDPWorking,
        needVPNClient: values.needVPNClient,
        needRemoteDesktop: values.needRemoteDesktop,
        configDone: values.configDone,
        needToRestart: values.needToRestart,
      },

      finalSolution: {
        actionPlanOne: {
          details: values.details1,
          remarks: values.remarks1,
        },
        actionPlanTwo: {
          details: values.details2,
          remarks: values.remarks2,
        },
        actionPlanThree: {
          details: values.details3,
          remarks: values.remarks3,
        },
      },

      softwareApplication: {
        managementSoftware: {
          options: values.moptions,
          others: values.mothers,
        },
        accountingSftware: values.accountingSftware,
        taxPrepSoftware: {
          options: values.toptions,
          others: values.tothers,
        },
        timeTrackSoftware: values.timeTrackSoftware,
        internalCommSoftware: {
          options: values.ioptions,
          others: values.iothers,
        },
        clientCommSoftware: values.clientCommSoftware,
        passwordSoftware: values.passwordSoftware,
      },
    };
    //console.log(data);

    if (!checkListId1) {
      axios
        .post(`${API_BASE}v1/ITChecklist/ITChecklistForm`, data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            //console.log(res.data.data._id);
            setCheckListId(res.data.data._id);
            //message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    } else {
      axios
        .post(`${API_BASE}v1/ITChecklist/updateITChecklist`, {
          ...data,
          ITChecklistId: checkListId1,
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            //console.log(res.data.data._id);
            setCheckListId(res.data.data._id);
            //message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    //console.log(checkListId);
    if (checkListId) {
      let userID = localStorage.getItem("userID");
      const data = {
        userID,
        mainStepNo: 10,
        subStepNo: 0,
        ITChecklistID: checkListId,
      };
      //console.log(data);

      //setIsLoading(true);
      dispatch(fillClientForm(data))
        .then((res) => {
          //setIsLoading(false);
          if (res.payload.statusCode === 200) {
            message.success(res.payload.message);
            if (!prop.done) {
              prop.setCurrent((prev) => prev + 1);
            } else {
              navigate("/client");
            }
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          //setIsLoading(false);
          console.log(err);
        });
    }
  }, [checkListId]);

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
      //onFieldsChange={handleChange}
    >
      <div className="event-wrap wrap-box pl-0">
        <div className="rows">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Company name
              </label>
              <Form.Item
                name="companyName"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Company Name*" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                Company website
              </label>
              <Form.Item
                name="companyWebsite"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Company Website" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                IT person
              </label>
              <Form.Item
                name="ITPerson"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="IT Person" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                IT person contact details
              </label>
              <Form.Item
                name="ITPersonDetails"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="IT Person Contact Details" />
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                IT person contact details
              </label>
              <Form.Item
                name="ITGuy"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={1}>In House</Radio>
                  <Radio value={2}>Vendor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          {/* site */}
          <div className="col-12">
            <h2 style={{ margin: "20px 0" }}>1. Site Connectivity</h2>
            <h5>1. Hardware Based - VPN</h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                Does customer is having firewall/VPN router ?
              </label>
              <Form.Item
                name="customerHavingVPN"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">SSl browser based VPN</label>
              <Form.Item
                name="ssiBasedVPN"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="SSl Browser based VPN" />
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Static live IP remote desktop
              </label>
              <Form.Item
                name="staticsRemoteDesktop"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <h5>2.Software/Application Based</h5>
            <span style={{ display: "block" }}>
              Client Preffered Remote Desktop Application (Over Internet){" "}
            </span>
            <span style={{ display: "block", marginBottom: "20px" }}>
              Unison Globus Preferred Remote Desktop Application (Over the
              Internet)
            </span>
            <p style={{ display: "block", marginBottom: "20px" }}>
              If Application Based Connectivity is there then we don’t require
              to setup any - all Credintial needs to provide by client end -
              according to that we need to set client machine.
            </p>
          </div>

          {/* 2.1 */}
          <div className="col-12">
            <h5>2.1 Any Software Based RDP Splashtop</h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                User name
              </label>
              <Form.Item
                name="userName1"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="User Name" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Password</label>
              <Form.Item
                name="passWord1"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Password placeholder="Website URL" />
              </Form.Item>
            </div>
          </div>

          {/* 2.2 */}
          <div className="col-12">
            <h5>2.2 Microsoft RDP - IP and Port No</h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                IP
              </label>
              <Form.Item
                name="ip"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="IP" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Port no.</label>
              <Form.Item
                name="portNumber"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Port No." />
              </Form.Item>
            </div>
          </div>

          {/* 2.3 */}
          <div className="col-12">
            <h5>2.3 Microsoft RDP - IP and Port No</h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Email ID
              </label>
              <Form.Item
                name="email"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Email ID" />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Credentials</label>
              <Form.Item
                name="credentials"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Credentials" />
              </Form.Item>
            </div>
          </div>

          {/* 2.4 */}
          <div className="col-12">
            <h5>2.4 AWESUN</h5>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Form.Item
                name="awesun"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="AWESUN" />
              </Form.Item>
            </div>
          </div>

          {/* 2.5 */}
          <div className="col-12">
            <h5>2.5 Anydesk</h5>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Form.Item
                name="anyDesk"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Anydesk" />
              </Form.Item>
            </div>
          </div>

          {/* 2.6 */}
          <div className="col-12">
            <h5>2.6 Other</h5>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Form.Item
                name="other"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Other" />
              </Form.Item>
            </div>
          </div>

          {/*Data*/}
          <div className="col-12">
            <h2 style={{ margin: "20px 0" }}>2.Data Exchange Details</h2>
          </div>

          <div className="col-lg-3">
            <h5>2.1 One Drive</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="oneDrive"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <h5>2.2 DropBox</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="dropBox"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <h5>2.3 GoogleDrive</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="googleDrive"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <h5>2.4 Citrix Share File</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="citrixShareFile"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-12">
            <h5>2.5 Other</h5>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Form.Item
                name="others"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="Other" />
              </Form.Item>
            </div>
          </div>

          {/*End*/}
          <div className="col-12">
            <h2 style={{ margin: "20px 0" }}>3.End Point (Computer) Details</h2>
          </div>
          <div className="col-lg-6">
            {/* 3.1 */}
            <div className="col-12 pl-0">
              <h5>3.1 Client OS details</h5>
            </div>
            <div className="form-group">
              <div className="select-venue">
                <Form.Item
                  name="clientOSDetails"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Select placeholder="Please Select">
                    <Option value={1}>Windows 10 Home Edition -32 Bit</Option>
                    <Option value={2}>Windows 10 Home Edition - 64 Bit</Option>
                    <Option value={3}>Windows 10 Professional - 32 Bit</Option>
                    <Option value={4}>Windows 10 Professional - 64 Bit</Option>
                  </Select>
                </Form.Item>
              </div>
              <p style={{ display: "block", marginTop: "10px" }}>
                If Home Edition - Then its Built for only 1 Session, if
                Professional License is there they need to Buy Separate License
                for Multilogin - Reference - Lucy - Client Need to Buy from
                local market only.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            {/* 3.2 */}
            <div className="col-12 pl-0">
              <h5>3.2 Network type</h5>
            </div>
            <div className="form-group">
              <div className="select-venue">
                <Form.Item
                  name="networkType"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Select placeholder="Please Select">
                    <Option value="---">---</Option>
                    <Option value={1}>Domain</Option>
                    <Option value={2}>Workgroup</Option>
                  </Select>
                </Form.Item>
              </div>
              <p style={{ display: "block", marginTop: "10px" }}>
                Required Permission for RDP Port Allowed in Windows
                Firewall/Antivirus in local Computer/via Group Policy.
              </p>
            </div>
          </div>
          {/* 3.3 */}
          <div className="col-lg-6">
            <div className="col-12 pl-0">
              <h5>3.3 User create / User name</h5>
            </div>
            <div className="form-group">
              <Form.Item
                name="userName3"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input placeholder="User Name" />
              </Form.Item>
              <p style={{ display: "block", marginTop: "10px" }}>
                ICreate User with Remote Desktop User Right and other Required
                Permission
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-12 pl-0">
              <h5>Password</h5>
            </div>
            <div className="form-group">
              <Form.Item
                name="passWord3"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Password placeholder="Password" />
              </Form.Item>
              <p style={{ display: "block", marginTop: "10px" }}>
                ICreate User with Remote Desktop User Right and other Required
                Permission
              </p>
            </div>
          </div>
          {/* 3.4 */}
          <div className="col-lg-6">
            <h5>3.4 Windows firewall</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="windowsFirewall"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Radio.Group>
                    <Radio value={true}>On</Radio>
                    <Radio value={false}>Off</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 3.5 */}
          <div className="col-lg-6">
            <div className="col-12 pl-0">
              <h5>3.5 Client antivirus details</h5>
            </div>
            <div className="form-group">
              <div className="select-venue">
                <Form.Item
                  name="clientAntiVirus"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Select>
                    <Option value="Others">Others</Option>
                    <Option value="Windows Defender">Windows Defender</Option>
                    <Option value="Mcafee">Mcafee</Option>
                    <Option value="Symantec">Symantec</Option>
                    <Option value="eset">eset</Option>
                  </Select>
                </Form.Item>
              </div>
              <p style={{ display: "block", marginTop: "10px" }}>
                Allowed RDP Port and Application From Antivirus
              </p>
            </div>
          </div>

          {/* 3.6 */}
          <div className="col-12">
            <h5>
              3.6 Check RDP is Working in There Local Network with Created
              Username and Password
            </h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="IsRDPWorking"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 3.7 */}
          <div className="col-xl-6 col-12">
            <h5>3.7 Need to setup VPN client</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="needVPNClient"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 3.8 */}
          <div className="col-xl-6 col-12">
            <h5>3.8 Need to setup remote desktop client setup</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="needRemoteDesktop"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 3.9 */}
          <div className="col-xl-6 col-12">
            <h5>3.9 Configuration done and verification</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="configDone"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 3.10 */}
          <div className="col-xl-6 col-12">
            <h5>3.10 Need to restart computer and again need to check</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="needToRestart"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>

          {/*Final*/}
          <div className="col-12">
            <h2 style={{ margin: "20px 0" }}>4. Final Solution</h2>
          </div>
          <div className="col-12">
            <h5>Action Plan 1 - For Remote Access</h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Details</label>
              <Form.Item
                name="details1"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Remarks For references</label>
              <Form.Item
                name="remarks1"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <h5>Action Plan 2 - Backup plan in case of Plan A Failed</h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Details</label>
              <Form.Item
                name="details2"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Remarks For references</label>
              <Form.Item
                name="remarks2"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <h5>
              Action Plan 3 - Backup Plan 2 in Case of Plan 1 & Plan 2 Failed
            </h5>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Details</label>
              <Form.Item
                name="details3"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="label-title">Remarks For references</label>
              <Form.Item
                name="remarks3"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <h2 style={{ margin: "20px 0" }}>5. Software / Applications</h2>
          </div>

          {/* 5.1 */}
          <div className="col-12 ">
            <h5>5.1 Workflow Management Software</h5>
            <Row>
              <div className="col-lg-6 pl-0">
                <div className="form-group">
                  <Form.Item
                    name="moptions"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Option!",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Please Select">
                      <Option value={1}>Verifyle</Option>
                      <Option value={2}>Corvee</Option>
                      <Option value={3}>ClickUp</Option>
                      <Option value={4}>Others</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6 p-col">
                <div className="form-group">
                  <Form.Item
                    name="mothers"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Option!",
                    //   },
                    // ]}
                  >
                    <Input placeholder="If any Other Workflow Software" />
                  </Form.Item>
                </div>
              </div>
            </Row>
          </div>
          {/* 5.2 */}
          <div className="col-12">
            <h5>5.2 Accounting Software</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="accountingSftware"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input placeholder="If any Accounting Software" />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 5.3 */}
          <div className="col-12 ">
            <h5>5.3 Tax Preparation Software</h5>
            <Row>
              <div className="col-lg-6 pl-0">
                <div className="form-group">
                  <Form.Item
                    name="tothers"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Option!",
                    //   },
                    // ]}
                  >
                    <Radio.Group>
                      <Radio value={1}>Ultratax</Radio>
                      <Radio value={2}>Drake</Radio>
                      <Radio value={3}>CCH Axcess</Radio>
                      <Radio value={4}>Lacerte</Radio>
                      <Radio value={5}>Proconnect</Radio>
                      <Radio value={6}>Others</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6 p-col">
                <div className="form-group">
                  <Form.Item
                    name="toptions"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Option!",
                    //   },
                    // ]}
                  >
                    <Input placeholder="If any Other Workflow Software" />
                  </Form.Item>
                </div>
              </div>
            </Row>
          </div>
          {/* 5.4 */}
          <div className="col-12">
            <h5>5.4 Time Tracking Software</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="timeTrackSoftware"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input placeholder="If any Time Tracking Software" />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 5.5 */}
          <div className="col-12">
            <h5>5.5 Internal Communication Software</h5>
            <Row>
              <div className="col-lg-6 pl-0">
                <div className="form-group">
                  <Form.Item
                    name="ioptions"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Option!",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Please Select">
                      <Option value={1}>Teams</Option>
                      <Option value={2}>Slack</Option>
                      <Option value={3}>Others</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6 p-col">
                <div className="form-group">
                  <Form.Item
                    name="iothers"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Option!",
                    //   },
                    // ]}
                  >
                    <Input placeholder="If any Other Internal Communication Software" />
                  </Form.Item>
                </div>
              </div>
            </Row>
          </div>
          {/* 5.6 */}
          <div className="col-lg-6">
            <h5>5.6 Client Communication platform</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="clientCommSoftware"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input placeholder="If any Client Communication platform" />
                </Form.Item>
              </div>
            </div>
          </div>
          {/* 5.7 */}
          <div className="col-lg-6">
            <h5>5.7 Password storage software</h5>
            <div className="col-12 pl-0">
              <div className="form-group">
                <Form.Item
                  name="passwordSoftware"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Option!",
                  //   },
                  // ]}
                >
                  <Input placeholder="If any Password storage software" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Form.Item
                name="iAgree"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Option!",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={true}>
                    I Agree with Provided Information and Approved Data!
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className="col-12 mt-4">
            <div className="view-btn">
              {/* <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-primary"
                      >
                          Submit
                      </Button> */}
              {/* <Button type="primary" htmlType="submit" className="btn-primary">
                Reset
              </Button> */}
              {/* <Button
                        type="secondary"
                        htmlType="submit"
                        className="btn-secondary"
                    >
                        <Link to="/client_it">Cancel</Link>
                    </Button> */}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default IT;
