import React, { useState } from "react";
import { Button, Form, Input, Layout, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import "../profiledetail.scss";
import "../../../scss/_col.scss";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { changepassword } from "../../../Redux/userSlice";
import { useDispatch } from "react-redux";
import STORAGE_KEYS from "../../../keys";
import Loading from "../../../components/common/loader";


const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  
  let userId = localStorage.getItem(STORAGE_KEYS.USER_ID)
    ? localStorage.getItem(STORAGE_KEYS.USER_ID)
    : document.cookie?.split(";")?.[1].split("=")?.[1];

  //const [newPassword, setNewPassword] = useState();
  //const [confirmPassword, setConfirmPassword] = useState();
  //const [passwordErr, setPasswordErr] = useState();  
  //const [values, setValues] = useState({ id: userId });
  // const changePasswordHandler = () => {
  //   setIsLoading(true);
  //   if (newPassword === confirmPassword) {
  //     const uppercaseRegExp = /(?=.*?[A-Z])/;
  //     const lowercaseRegExp = /(?=.*?[a-z])/;
  //     const digitsRegExp = /(?=.*?[0-9])/;
  //     const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  //     const minLengthRegExp = /.{8,}/;
  //     const passwordLength = confirmPassword?.length;
  //     const uppercasePassword = uppercaseRegExp.test(confirmPassword);
  //     const lowercasePassword = lowercaseRegExp.test(confirmPassword);
  //     const digitsPassword = digitsRegExp.test(confirmPassword);
  //     const specialCharPassword = specialCharRegExp.test(confirmPassword);
  //     const minLengthPassword = minLengthRegExp.test(confirmPassword);

  //     let errMsg = "";
  //     if (passwordLength === undefined) {
  //       errMsg = "Password is empty";
  //     } else if (!uppercasePassword) {
  //       errMsg = "At least one Uppercase";
  //     } else if (!lowercasePassword) {
  //       errMsg = "At least one Lowercase";
  //     } else if (!digitsPassword) {
  //       errMsg = "At least one digit";
  //     } else if (!specialCharPassword) {
  //       errMsg = "At least one Special Characters";
  //     } else if (!minLengthPassword) {
  //       errMsg = "At least minumum 8 characters";
  //     } else {
  //       errMsg = "";
  //     }
  //     setPasswordErr(errMsg);
  //     if (errMsg != "") {
  //       setIsLoading(false);
  //       message.error(errMsg);
  //     }

  //     if (errMsg === "" && values?.currentPassword != undefined) {
  //       dispatch(changepassword({ ...values, newPassword: confirmPassword }))
  //         .then((res) => {
  //           setIsLoading(false);
  //           if (res.payload.statusCode === 200) {
  //             message.success(res.payload.message);
  //             localStorage.clear();
  //             document.cookie =
  //               "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  //             document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  //             navigate("/login");
  //           } else {
  //             setIsLoading(false);
  //             message.error(res.payload.message);
  //           }
  //         })
  //         .catch((err) => {
  //           setIsLoading(false);
  //           console.log(err);
  //         });
  //     }
  //   } else {
  //     setIsLoading(false);
  //     message.error("both password must be same.");
  //   }
  // };

  const onFinish = (values) => {
    //console.log(values);
    
    if(userId){
    setIsLoading(true);
    dispatch(changepassword({ ...values, id:userId}))
    .then((res) => {
      setIsLoading(false);
      if (res.payload.statusCode === 200) {
        message.success(res.payload.message);
        localStorage.clear();
        document.cookie =
          "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
        navigate("/login");
      } else {
        message.error(res.payload.message);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    })
  }
  };

  return (
    <>
      <Loading isSuspense={isLoading}>
        <Layout className="site-layout-background">
          <Content className="admin-webpanel-content">
            <Breadcrumbs />
            <div className="filter-wrap  event-wrap">
              <h2 className="title">Change Password</h2>
            </div>
            
            <div className="event-wrap">
            <Form
                  className="sectionadd-form"
                  form={form}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label-title">
                        <span className="required">*</span>
                        Old password
                      </label>
                      <Form.Item
                          name="currentPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter old password!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  value &&
                                  (value.search(/(?=.*?[A-Z])/) === -1 ||
                                  value.search(/(?=.*?[a-z])/) === -1 ||
                                  value.search(/(?=.*?[0-9])/) === -1 ||
                                  value.search(/(?=.*?[#?!@$%^&*-])/) === -1 ||
                                  value.search(/.{8,}/) === -1)                                      
                                ) {
                                  return Promise.reject("Password should contain atleast one upper case, lower case, digit, special symbol and length should be atleast 8");
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                      <Input.Password
                        placeholder="Enter old password"
                      />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label-title">
                        <span className="required">*</span>
                        New password
                      </label>
                      <Form.Item
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter new password!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  value &&
                                  (value.search(/(?=.*?[A-Z])/) === -1 ||
                                  value.search(/(?=.*?[a-z])/) === -1 ||
                                  value.search(/(?=.*?[0-9])/) === -1 ||
                                  value.search(/(?=.*?[#?!@$%^&*-])/) === -1 ||
                                  value.search(/.{8,}/) === -1)                                      
                                ) {
                                  return Promise.reject("Password should contain atleast one upper case, lower case, digit, special symbol and length should be atleast 8");
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          hasFeedback
                        >
                      <Input.Password
                        placeholder="Enter new password"
                      />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label-title">
                        <span className="required">*</span>
                        Confirm password
                      </label>
                      <Form.Item
                          name="cNewPassword"
                          dependencies={['newPassword']}
                          rules={[
                            {
                              required: true,
                              message: "Please enter confirm password!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  value &&
                                  form.getFieldValue().newPassword !== value
                                ) {
                                  return Promise.reject("new password and confirm password should match");
                                }
                                
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                      <Input.Password
                        placeholder="Enter confirm password"
                      />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="view-btn">
                      <Link to="/profile">
                        <Button
                          type="primary"
                          htmlType="button"
                          className="btn-secondary m-btn"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary"
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                  
                </div>
              </Form>
            </div>
          </Content>
        </Layout>
      </Loading>
    </>
  );
};

export default ChangePassword;
