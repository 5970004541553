import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {};

export const scheduleMeeting = createAsyncThunk(
  "clientMeeting/scheduleMeeting",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientMeeting/scheduleMeeting`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateMeeting = createAsyncThunk(
  "clientMeeting/updateMeeting",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientMeeting/updateMeeting`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const deleteMeeting = createAsyncThunk(
  "clientMeeting/deleteMeeting",
  async (data) => {
    try {
      const response = await axios.delete(
        `${API_BASE}v1/clientMeeting/deleteMeeting/${data}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getMeetingDetails = createAsyncThunk(
  "clientMeeting/getMeetingDetails",
  async (data) => {
    try {
      const response = await axios.get(
        `${API_BASE}v1/clientMeeting/getMeetingDetails/${data}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const stopRecurringMeeting = createAsyncThunk(
  "clientMeeting/stopRecurringMeeting",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientMeeting/stopRecurringMeeting`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getListOfMeeting = createAsyncThunk(
  "clientMeeting/getListOfMeeting",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientMeeting/getListOfMeeting`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getListOfUpcommingMeeting = createAsyncThunk(
  "clientMeeting/getListOfUpcommingMeeting",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientMeeting/getListOfUpcommingMeeting`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getMonthlyListOfUpcommingMeeting = createAsyncThunk(
  "clientMeeting/getMonthlyListOfUpcommingMeeting",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientMeeting/getMonthlyListOfUpcommingMeeting`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const scheduleMeetingSlice = createSlice({
  name: "scheduleMeeting",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(scheduleMeeting.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(deleteMeeting.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(getMeetingDetails.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default scheduleMeetingSlice.reducer;
