import {
  DeleteTwoTone,
  EditTwoTone,
  FileAddTwoTone,
  FormOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Layout,
  message,
  Modal,
  pagination,
  Row,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import MDelete from "../../../components/common/Delete";
import { deleteMail, listMail } from "../../../Redux/emailSlice";
import "./temp.scss";

function Template() {
  const dispatch = useDispatch();
  const [editInfoVisible, setEditInfoVisible] = useState(false);
  const [EditInfoLoading, setEditInfoLoading] = useState(false);

  const [DeleteInfoVisible, setDeleteInfoVisible] = useState(false);

  const [users, setUsers] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [column, setColumn] = useState();
  const [order, setOrder] = useState();
  const [id, setId] = useState();
  const [reload, setReload] = useState(false);

  const showEditInfo = () => {
    setDeleteInfoVisible(true);
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
  });

  // modal-2
  const [editInfoVisible2, setEditInfoVisible2] = useState(false);

  // modal-2
  const showEditInfo2 = () => {
    setEditInfoVisible2(true);
  };

  const dataSource = [
    {
      key: "1",
      list: "NEW CLIENT REGISTERED",
    },
    {
      key: "2",
      list: "TRIAL SERVICE REQUEST",
    },
    {
      key: "3",
      list: "TRAIL JOB UPDATE",
    },
    {
      key: "4",
      list: "SELECT PACKAGE",
    },
    {
      key: "5",
      list: "SLA",
    },
    {
      key: "6",
      list: "SLA UPDATED",
    },
    {
      key: "7",
      list: "TEAM ASSIGNED",
    },
    {
      key: "8",
      list: "MEETING SCHEDULED",
    },
    {
      key: "9",
      list: "IT CHECKLIST DONE",
    },
    {
      key: "10",
      list: "INVOICE UPDATE",
    },
    {
      key: "11",
      list: "REVIEW FORM",
    },
  ];

  const columns = [
    {
      title: "Title",
      dataIndex: "subject",
      key: "title",
      sorter: true,
    },
    // {
    //   title: "Last Action Date",
    //   dataIndex: "date",
    //   key: "date",
    //   sorter: {
    //     compare: (a, b) => a.Status - b.Status,
    //     multiple: 3,
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    //   key: "Status",
    //   render: (row) => (
    //     <div className="active-desc">
    //       <div className="active">{row?.active}</div>
    //       {/* <div className='inactive'>{row.inactive}</div> */}
    //     </div>
    //   ),
    //   sorter: {
    //     compare: (a, b) => a.Status - b.Status,
    //     multiple: 3,
    //   },
    // },
    {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      className: "view",
      render: (row) => (
        <ul className="action-col">
          {/* <li>
                    <button className="common-btn" onClick={showEditInfo3}><EyeFilled /></button>
                  </li> */}
          <li>
            <Link
              to={`/email/edit/${row?._id}`}
              className="common-btn"
              onClick={showEditInfo2}
            >
              <EditTwoTone />
            </Link>
          </li>
          <li>
            <a className="common-btn" onClick={showEditInfo}>
              <DeleteTwoTone
                onClick={() => {
                  setDeleteInfoVisible(true);
                  setId(row?._id);
                }}
              />
            </a>
          </li>
        </ul>
      ),
    },
  ];

  // table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onChange = (pagination, filters, sorter, extra) => {};

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      listMail({
        limit: limit,
        skip: skip,
        search: search,
        column: column,
        order: order,
      })
    )
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setUsers(
            res.payload.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
          setTotalUsers(res.payload.data.total_records);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [limit, skip, search, column, order, reload]);

  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === "sort") {
      setColumn(sorter?.field);
      setOrder(
        sorter?.order === "ascend"
          ? "asc"
          : sorter?.order === "descend"
          ? "desc"
          : ""
      );
    } else {
      setColumn();
      setOrder();
    }
  };
  const handleCancel = () => {
    setDeleteInfoVisible(false);
  };
  const handleOk = () => {
    setIsLoading(true);
    dispatch(deleteMail(id))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          setDeleteInfoVisible(false);
          setReload(!reload);
        } else {
          message.error(res.payload.message);
          setDeleteInfoVisible(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setDeleteInfoVisible(false);
      });
  };
  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs />

          <div className="filter-wrap  event-wrap">
            <div>
              <h2 className="title">Email Template</h2>
            </div>
            <Row justify="end" className="btn-box">
              {/* <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="#">Delete</Link>
              </Button> */}
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="/email/add">Add Email Template</Link>
              </Button>
            </Row>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <Row className="search" justify="end">
                <div className="col-lg-3 col-sm-4">
                  <Input
                    placeholder="Search here"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </Row>
              <div className="common-table">
                <Table
                  bordered
                  columns={columns}
                  Loading={isLoading}
                  dataSource={users}
                  scroll={{ x: 870 }}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalUsers,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* modale */}
          {/* <MDelete
            setDeleteInfoVisible={setDeleteInfoVisible}
            DeleteInfoVisible={DeleteInfoVisible}
          /> */}

          {DeleteInfoVisible ? (
            <Modal
              visible={DeleteInfoVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              centered={true}
              className="common-modal edit-modal"
            >
              <div className="modal-body">
                <div className="desc">
                  {/* <span className="icon"><ExclamationCircleOutlined /></span> */}
                  <h2 className="title">
                    Are you sure you want to delete Email Template ?
                  </h2>
                  <p>Your action can't be undone</p>
                </div>
              </div>
            </Modal>
          ) : null}
        </Content>
      </Layout>
    </>
  );
}

export default Template;
