import React from "react";
import "./header.scss";
import { toAbsoluteUrl } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown, message } from "antd";
import { API_BASE } from "../../../constants/api";
import axios from "axios";
import { UserOutlined } from "@ant-design/icons";

const Header = () => {
  let userName = localStorage.getItem("userName");
  const navigate = useNavigate();

  const logout = () => {
    const userId = localStorage.getItem("user");
    const accessToken = localStorage.getItem("access_token");
    const data = { userId, accessToken };

    axios
      .post(`${API_BASE}v1/user/logout`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          localStorage.clear();
          message.success(res.data.message);
          navigate("/login");
        } else {
          message.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/login" onClick={logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="header-wrap">
        <div className="logo">
          <Link className="header-logo" to="/dashboard">
            <img
              src={toAbsoluteUrl("/assets/images/ug-front-1.png")}
              // src={toAbsoluteUrl("/assets/images/white_unison-1.png")}
              alt="logo"
            />
          </Link>
        </div>
        {/* <div className='header-btn'>
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
        </div> */}
        <div className="header-right">
          {/* <div className="notification"><Notification /><span className="number">11</span></div> */}
          <Dropdown overlay={menu} trigger={["click"]}>
            <span className="person-chat" onClick={(e) => e.preventDefault()}>
            
              <img
                src={toAbsoluteUrl("/assets/images/imgicon.svg")}
                //src={<UserOutlined />}
                alt="person"
              /> 
              {userName}
            </span>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Header;
