import { Form, Input, message, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE } from "../../../constants/api";

const Review = (prop) => {
  // radio
  const [value, setValue] = useState(1);
  const [form] = Form.useForm();
  const [reviewData, setReviewData] = useState();

  const navigate = useNavigate();

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    axios
      .get(`${API_BASE}v1/clientForm/getFeedbackDetails/${clientid}`)
      .then((res) => {
        //console.log(res.data.data);
        setReviewData(res.data.data);
      })
      .catch((err) => {
        //setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (reviewData) {
      //console.log(reviewData);
      form.setFieldsValue({
        communicationWithBDE: reviewData.communicationWithBDE,
        communicationWithManager: reviewData.communicationWithManager,
        exceptionalThingsDescription: reviewData.exceptionalThingsDescription,
        improvedThingsDescription: reviewData.improvedThingsDescription,
        itOnBoarding: reviewData.itOnBoarding,
        overallOnBoardingProcess: reviewData.overallOnBoardingProcess,
        promptness: reviewData.promptness,
        resourceInterviewProcess: reviewData.resourceInterviewProcess,
        selectionOfServices: reviewData.selectionOfServices,
        signingOfAgreements: reviewData.signingOfAgreements,
        trialJobProcess: reviewData.trialJobProcess,
      });
    }
  }, [reviewData]);

  const onFinish = (values) => {
    //console.log(values);

    let clientid = localStorage.getItem("clientID");
    const data = {
      clientID: clientid,
      ...values,
    };
    //console.log(data);

    axios
      .post(`${API_BASE}v1/clientForm/addFeedback`, data)
      .then((res) => {
        //setIsLoading(false);
        if (res.data.statusCode === 200) {
          //console.log(res.data.message);
          message.success(res.data.message);
          if (!prop.done) {
            prop.setCurrent((prev) => prev + 1);
          } else {
            navigate("/client");
          }
        }
      })
      .catch((err) => {
        //setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
      <div className="event-wrap mt-0">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Communication with business development executive
              </label>
              <Form.Item name="communicationWithBDE" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Communication with manager
              </label>
              <Form.Item name="communicationWithManager" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Selection of services and resources
              </label>
              <Form.Item name="selectionOfServices" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Signing of agreements
              </label>
              <Form.Item name="signingOfAgreements" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Trial job process
              </label>
              <Form.Item name="trialJobProcess" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Resource interview process
              </label>
              <Form.Item name="resourceInterviewProcess" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                IT on boarding
              </label>
              <Form.Item name="itOnBoarding" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Promptness
              </label>
              <Form.Item name="promptness" initialValue={1}>
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Overall on boarding process
              </label>
              <Form.Item name="overallOnBoardingProcess" initialValue={1}>
                <Radio.Group>
                  <Radio value={1}>Very Good</Radio>
                  <Radio value={2}>Good</Radio>
                  <Radio value={3}>Average</Radio>
                  <Radio value={4}>Below Average</Radio>
                  <Radio value={5}>Poor</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                Things you feel were exceptional.
              </label>
              <Form.Item name="exceptionalThingsDescription">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                {/* <span className="required">*</span> */}
                Things you feel could be improved.
              </label>
              <Form.Item name="improvedThingsDescription">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Review;
