import React, { Suspense } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import store from '../store';
import Routes from './routes';
import ScrollToTop from '../components/common/scroll';
import Loader from '../components/common/loader';
import { axiosInterceptor } from '../intercepter';

const { PUBLIC_URL } = process.env;

axiosInterceptor();

const AppContainer = () => (
  <Provider store={store}>
    <Suspense fallback={<Loader isSuspense />}>
      <Loader>
        <BrowserRouter basename={PUBLIC_URL}>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </BrowserRouter>
      </Loader>
    </Suspense>
  </Provider>
)

export default AppContainer;