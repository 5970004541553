import React, { useState } from "react";
import IT from "../../../components/common/ITChecklist";
import { Radio } from "antd";

const ITChecklist = (prop) => {
  // radio
  const [value, setValue] = useState(0);

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      <div className="checklist-box">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              {/* <label className="label-title">
                    <span className="required">*</span>
                    IT person contact details
                    </label> */}
              {/* <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Company IT Detail</Radio>
                <Radio value={2}>Use Unison Global IT Setting</Radio>
              </Radio.Group> */}
            </div>
          </div>
        </div>

        <IT refs={prop.refs} setCurrent={prop.setCurrent} />
      </div>
    </>
  );
};

export default ITChecklist;
