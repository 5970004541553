import React from "react";
import Tabbbings from "./tabs";

const Team = (prop) => {
  return (
    <>
      <div className="event-wrap">
        <Tabbbings refs={prop.refs} setCurrent={prop.setCurrent} />
      </div>
    </>
  );
};

export default Team;
