import { Button, Col, Form, message, Row, Spin, Table } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { uploadFile } from "../../../Redux/uploadSlice";
import { useDispatch } from "react-redux";
import {
  fillClientForm,
  getClientDetail,
} from "./../../../Redux/clientFormSlice";

import AddNote from "../../../components/common/AddNote";
import axios from "axios";
import { API_BASE } from "../../../constants/api";
import Upload from "antd/lib/upload/Upload";

function Ndnc(prop) {
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [links, setLinks] = useState([]);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [notes, setNotes] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  const [pageName, setPageName] = useState();
  const [NdncData, setNdncData] = useState();
  const [excelInfoVisible, setexcelInfoVisible] = useState(false);
  
  const dispatch = useDispatch();
  
  const baseURL = "https://clienton-boarding.s3.amazonaws.com/"

  useEffect(() => {
    setPageName(prop.pageName);
  }, [prop]);

  useEffect(() => {
    if(pageName){
    let userID = localStorage.getItem("userID");
    let client = localStorage.getItem("clientID");
    let url, data;
    if(pageName === "NDNC"){
      url ="user/generateNDNCPdf"
      data = { userId: userID }
    } else {
      url = "clientForm/generateSLA_3_Pdf"
      data = { client }
    }
    
    setIsLoading(true)
    axios
      .post(`${API_BASE}v1/${url}`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {    
          //console.log(res.data.data.generatedNDNCPdf)  
          const file = pageName === "NDNC" ? res?.data?.data?.generatedNDNCPdf : res?.data?.data?.generatedSLAPdf 
          let fileName = file[file.length-1]
          let tarray = [
            {
              uid: "-1",
              name: `Generated Doc`,
              status: "done",
              url: res.data.data.url + fileName 
            },
          ];
          setFileList1(tarray);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    }
  }, [pageName]);

  //getting Client Step 4 data
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (pageName) {
      
      dispatch(getClientDetail(clientid))
        .then((res) => {
          
          if (res.payload.statusCode === 200) {
            //console.log(res.payload.data);
            setNdncData(res.payload.data);
            let tarray = [];
            if (pageName === "NDNC") {
              //console.log(fileList1);
              if (res.payload.data.NDNCDoc.length) {
                res.payload.data.NDNCDoc.map((data,index) => {
                  tarray.push({                    
                      uid: index,
                      name: "Uploaded " + pageName + " File",
                      status: "done",
                      url: baseURL + "ndnc-pdf/"+  data,                    
                  })
                })
                //console.log(tarray)
                setFileList(tarray);
              }
            }  
            else {
              if (pageName === "SLA") {
                if (res.payload.data.SLADoc.length) {
                  res.payload.data.SLADoc.map((data,index) => {
                    tarray.push({                    
                        uid: index,
                        name: "Uploaded " + pageName + " File",
                        status: "done",
                        url: baseURL + "sla-pdf/" +  data,                    
                    })
                  })
                  setFileList(tarray);
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pageName]);

  useEffect(() => {
    //console.log([...fileList, ...fileList1])
    setFileList2([...fileList, ...fileList1])
  }, [fileList, fileList1])

  //getting client Notes
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    const data = {
      limit: 10000,
      skip,
      clientID: clientid,
      notesType: pageName === "NDNC" ? 1 : 2,
    };
    
    if (pageName) {
      //setIsLoading(true);
      axios
        .post(`${API_BASE}v1/clientForm/getClientNotesList`, data)
        .then((res) => {
          //setIsLoading(false);
          if (res.data.statusCode === 200) {
            setNotes(
              res.data.data.list.map((data) => {
                return { ...data, key: data._id };
              })
            );
            setTotalNotes(res.data.data.list.length);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          //setIsLoading(false);
          console.log(err);
        });
    }
  }, [skip, pageName, excelInfoVisible]);

  const onFinish = (values) => {
    console.log(fileList3)
    if(fileList3.length === 0){
      prop.setCurrent((prev) => prev + 1);
    } else {
    let links1;
      const allPromises = fileList3.map((file) => {
        let formData = new FormData();
        formData.append("image", file.originFileObj);
        formData.append("moduleName", pageName === "NDNC" ? "ndnc-pdf" : "sla-pdf");
        setIsLoading(true)
        return dispatch(uploadFile(formData)); // Returns a promise
      });

      Promise.all(allPromises).then((res) => {
        setIsLoading(false)
        links1 = res.map((data1) => data1.payload.data.name);
        setLinks(links1);
        // ... do something after all promises were resolved ...
      });
    }
  };

  useEffect(() => {
    let data;
    let userID = localStorage.getItem("userID");
    let array = pageName === "NDNC"  ?  NdncData?.NDNCDoc?.concat() : NdncData?.SLADoc?.concat() || []
    //array = array.slice(1)
    console.log(array)
    if (links.length) {
      array = array.concat(links[0])
      console.log(array)
      data = {
        userID,
        [pageName + "Doc"]: array,
        mainStepNo: pageName === "NDNC" ? 4 : 7,
        subStepNo: 0,
        status: pageName === "NDNC" ? 2 : 7,
      };
      console.log(data)
      dispatch(fillClientForm(data))
        .then((res) => {
          if (res.payload.statusCode === 200) {
            message.success(res.payload.message);
            prop.setCurrent((prev) => prev + 1);
            setFileList()
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => { 
          console.log(err);
        });
    }
  }, [links]);

  const props = {
    fileList3,
    maxCount: 1,
    accept: ".pdf",
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      //console.log(info);
      setFileList3(info.fileList);
    },
  };

  const props1 = {
    fileList : fileList2,
    //maxCount: 1,
    accept: ".pdf",
    beforeUpload: () => {
      return false;
    },
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const columns = [
    {
      title: "Change Request Notes",
      dataIndex: "notes",
      key: "Notes",
    },
  ];

  const note = () => {
    setexcelInfoVisible(true);
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
        <>
        <Row>
          <Col offset={20} span={4}>
            <Button className="btn-primary" type="primary" onClick={note}>
              Add Note
            </Button>
          </Col>
        </Row>

        {isLoading ? <Spin /> :
        <>   
        <div className="event-wrap">
          <Row>
            <div className="col-lg-6">
           
              <Form.Item
                className="upload-changepdf"
                name="Ndnc"
                label={pageName + " Doc"}
                rules={[
                  {
                    required: fileList.length ? false : true,
                    message: "Please Select File!",
                  },
                ]}
              >
                <Dragger {...props} height="auto">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag {pageName} file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single {pageName} upload. Strictly upload PDF
                    file
                  </p>
                </Dragger>
              </Form.Item>
            </div>
          </Row>
          <Row>
          <div>
            <Upload {...props1}>
              {/* <Button icon={<UploadOutlined />}>Click to Upload</Button> */}
            </Upload>
          </div>
          </Row>      
          <Row>
            <div className="col-lg-12">
              <div className="common-table">
                <Table
                  bordered
                  dataSource={notes}
                  columns={columns}
                  scroll={{ x: 800 }}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalNotes,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              </div>
            </div>
          </Row>

          <AddNote
            notesType={pageName === "NDNC" ? 1 : 2}
            setexcelInfoVisible={setexcelInfoVisible}
            excelInfoVisible={excelInfoVisible}
          />
        </div>
       </> 
      }
        </>        
    </Form>
  );
}

export default Ndnc;
