import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  service: {},
  serviceList: {},
};

export const getAllService = createAsyncThunk(
  "service/getAllService",
  async () => {
    try {
      const response = await axios.get(`${API_BASE}v1/service/getAllService`);
      //console.log(response);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getAllServiceSubServie = createAsyncThunk(
  "service/getAllServiceSubServie",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/service/getListOfServiceTypeSubServiceType`,
        data
      );
      //console.log(response);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllService.fulfilled, (state, action) => {
      //console.log(action.payload.data.serviceList);
      state.serviceList = action.payload.data.serviceList;
    });
    builder.addCase(getAllServiceSubServie.fulfilled, (state, action) => {
      //state.forgotPasswordResponse = action.payload;
      //state.isLoggedIn = false;
    });
  },
});

export default serviceSlice.reducer;
