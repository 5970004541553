import React, { useEffect, useState } from "react";
import "./upload.scss";
import { Upload, Modal, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_BASE } from "../../../constants/api";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
let initialState = {
  previewVisible: false,
  previewImage: "",
  previewTitle: "",
  fileList: [],
};

const UploadFile = ({ setUserImage, userImage, accept }) => {
  const [image, setImage] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const { previewVisible, previewImage, fileList, previewTitle } = image;

  //let baseURL = API_BASE?.slice(0, API_BASE?.length - 1);
  let baseURL = "https://clienton-boarding.s3.amazonaws.com/logo/"
  useEffect(() => {
    //console.log(initialState);
    if (userImage) {
      //console.log(userImage);
      setImage({
        ...initialState,
        fileList: [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: baseURL + userImage,
          },
        ],
      });
    }
  }, [userImage]);

  const handleChange = ({ fileList }) => {
    //console.log(fileList);
    if (fileList.length) {
      fileList[0] = { ...fileList[0], status: "success" };
    }
    setImage({ ...image, fileList });
  };

  const handlePreview = async (file) => {
    //console.log(file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    //console.log(file);
    setImage({
      ...image,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleCancel = () => setImage({ ...image, previewVisible: false });

  const handleUpload = (file) => {
    //console.log(file);
    let formData = new FormData();
    formData.append("image", file);
    formData.append("moduleName", "logo");

    setIsLoading(true);
    axios
      .post(`${API_BASE}common/S3upload`, formData)
      .then((res) => {
        setIsLoading(false);
        //console.log(res.data.data.name);
        setUserImage(res.data.data.name);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleRemove = (file) => {
    setUserImage();
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Upload
            action={handleUpload}
            accept={accept}
            // beforeUpload={() => {
            //   return false;
            // }}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemove}
          >
            {fileList?.length >= 1 ? null : uploadButton}
          </Upload>

          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </>
      )}
    </>
  );
};

export default UploadFile;
