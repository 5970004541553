import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routeMap from "../routes";

const Login = lazy(() => import("../pages/Login"));
const ForogtPassword = lazy(() => import("../pages/ForogtPassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const SetFirstPassword = lazy(() => import("../pages/SetFirstPassword"));

const Routing = () => {
  //let isPasswordChanged = localStorage.getItem("isPasswordChanged");
  //console.log(isPasswordChanged === "false");
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForogtPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/set-password/false" element={<SetFirstPassword />} />
      ...{routeMap}
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Routing;
