import React, { useEffect, useState } from "react";
import "./member.scss";
import { Layout, Input, Button, Table, Row, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import axios from "axios";
import { API_BASE } from "../../../../constants/api";
import MDelete from "../../../../components/common/Delete";

const Service = () => {
  const [service, setService] = useState();
  const [totalService, setTotalService] = useState();

  const [ModalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState();

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    const data = { limit, skip, search, column, order };
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/service/getServiceList`, data)
      .then((res) => {
        //console.log(res.data);
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setService(
            res.data.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
          setTotalService(res.data.data.total_records);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [limit, skip, search, column, order]);

  const handleSort = (value) => {
    setColumn(value);
    setOrder((prev) => {
      if (prev === "asc") return "desc";
      else return "asc";
    });
  };

  const handleId = (id) => {
    setId(id);
    setModalVisible(true);
  };

  const columns = [
    {
      title: (
        <div style={{ cursor: "pointer" }} onClick={() => handleSort("title")}>
          Service
        </div>
      ),
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "No of Services",
    //   dataIndex: "NOS",
    //   key: "NOS",
    // },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("isActive")}
        >
          Account Status
        </div>
      ),
      dataIndex: "isActive",
      key: "isActive",
      render: (row) => (
        <div className="active-desc">
          <div className="active">{row ? "Active" : "InActivate"}</div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: "view",
      render: (row, record) => (
        <ul className="action-col">
          <li className="/user/edit_user">
            <Link
              to="/data/service/edit"
              className="common-btn"
              state={{ id: record._id }}
            >
              <EditTwoTone />
            </Link>
          </li>
          <li>
            <DeleteTwoTone onClick={() => handleId(record._id)} />
          </li>
        </ul>
      ),
    },
  ];

  return (
    <Layout className="site-layout-background">
      <Content className="admin-webpanel-content">
        <Breadcrumbs content="List of Services" />

        <div className="filter-wrap  event-wrap">
          <div>
            <h2 className="title">Manage Services</h2>
          </div>
          <Row justify="end" className="btn-box">
            <Button type="primary" htmlType="submit" className="btn-primary">
              <Link to="#">Delete</Link>
            </Button>
            <Button type="primary" htmlType="submit" className="btn-primary">
              <Link to="/data/service/add">Add New Service </Link>
            </Button>
          </Row>
        </div>

        <div className="remove-wrap">
          <div className="event-wrap">
            <Row className="search" justify="end">
              <div className="col-lg-3 col-sm-4">
                <Input
                  placeholder="Search here"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </Row>
            <div className="common-table">
              <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={service}
                scroll={{ x: 870 }}
                pagination={{
                  current: page,
                  defaultPageSize: limit,
                  total: totalService,
                  showTotal: (total) => `Total ${total} records`,
                  onChange: (page, pagesize) => {
                    setPage(page);
                    setSkip((page - 1) * pagesize);
                  },
                }}
              />
            </div>
          </div>
        </div>

        <MDelete
          setModalVisible={setModalVisible}
          ModalVisible={ModalVisible}
          content1="Are you sure you want to delete service ?"
          content2="Your action can't be undone"
          status="delete"
          id={id}
          data={service}
          setData={setService}
          setTotalData={setTotalService}
          api="service/deleteService"
        />
      </Content>
    </Layout>
  );
};

export default Service;
