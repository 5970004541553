import { Button, Form, Input, Layout, message, Row, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../../components/common/Breadcrumb";
import { API_BASE } from "../../../../../constants/api";

function AddEditExpertise(prop) {
  const [pageName, setPageName] = useState();
  const [pageType, setPageType] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    setPageName(prop.page);
    setPageType(prop.pageType);
  }, [prop]);
  //console.log(prop);

  //Edit Form
  const [content, setContent] = useState();
  const location = useLocation();

  useEffect(() => {
    if (pageType === "Edit") {
      let url;
      if (pageName === "Industry") {
        url = "industry/getIndustryDetails";
      } else if (pageName === "Software") {
        url = "software/getSoftwareById";
      } else if (pageName === "Expertise") {
        url = "expertise/getExpertiseById";
      }

      if (pageName) {
        axios
          .get(`${API_BASE}v1/${url}/${location?.state?.id}`)
          .then((res) => {
            //console.log(res.data.data);
            if (res.data.statusCode === 200) {
              setContent(res.data.data);
            } else {
              message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [pageName]);

  //Set Form
  useEffect(() => {
    if (content && pageType === "Edit") {
      form.setFieldsValue({
        title: content.title,
        isActive: content.isActive,
      });
    }
  }, [content]);

  //Form Submit
  const onFinish = (values) => {
    //console.log(values);

    let url, data1;

    if (pageType === "Add") {
      if (pageName === "Industry") {
        url = "industry/addIndustry";
        data1 = { ...values };
      } else if (pageName === "Software") {
        url = "software/addSoftware";
        data1 = { ...values };
      } else if (pageName === "Expertise") {
        url = "expertise/addExpertise";
        data1 = { ...values };
      }
    } else {
      if (pageName === "Industry") {
        url = "industry/updateIndustry";
        data1 = { ...values, industryId: location.state.id };
      } else if (pageName === "Software") {
        url = "software/updateSoftware/" + location.state.id;
        data1 = { ...values, softwareId: location.state.id };
      } else if (pageName === "Expertise") {
        url = "expertise/updateExpertise/" + location.state.id;
        data1 = { ...values, expertiseId: location.state.id };
      }
    }

    if (pageName) {
      axios
        .post(`${API_BASE}v1/${url}`, data1)
        .then((res) => {
          //console.log(res.data.message);
          if (res.data.statusCode === 200) {
            message.success(res.data.message);
            navigate(
              "/data/" +
                pageName?.charAt?.(0)?.toLowerCase() +
                pageName?.slice(1)
            );
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content={pageType + " " + pageName} />

          <div className="filter-wrap  event-wrap">
            <div>
              <h2 className="title">{pageName}</h2>
            </div>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <Form
                className="sectionadd-form"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  isActive: true,
                }}
              >
                <Row>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">{pageName} Name</label>

                      <Form.Item
                        name="title"
                        rules={[
                          {
                            whitespace: true,
                            message: "name can't be empty!",
                          },
                          {
                            required: true,
                            message: "Please enter " + pageName + " name!",
                          },
                          () => ({
                            validator(_, value) {
                              // if (value && value.search(/^[a-zA-Z]+$/) === -1) {
                              //   return Promise.reject("Provide valid name");
                              // }
                              if (
                                value &&
                                (value.length < 2 || value.length > 50)
                              ) {
                                return Promise.reject(
                                  "name must contain at least 2 character"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input placeholder={pageName + " Name"} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">
                        Status (Inactive/Active)
                      </label>
                      <Form.Item name="isActive" valuePropName="checked">
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                </Row>

                <Row>
                  <div className="col-12 mt-4">
                    <div className="view-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary"
                      >
                        Save
                      </Button>
                      <Button type="secondary" className="btn-secondary">
                        <Link
                          to={
                            "/data/" +
                            pageName?.charAt?.(0)?.toLowerCase() +
                            pageName?.slice(1)
                          }
                        >
                          Cancel
                        </Link>
                      </Button>
                    </div>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default AddEditExpertise;
