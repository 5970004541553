import React, { useState, useEffect, useRef } from "react";
import { Button, Steps, Layout, message, Modal, Spin, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import "../client.scss";
import Tabbbing from "./tabs";
import Job from "./Job";
import Trial from "../../../Admin/Trial";
import Information from "./Information";
import Ndnc from "../../../Admin/Ndnc";
import Team from "../../../Admin/STeamMember";
import Review from "../../../Admin/Review";
//import Meeting from "../../../Admin/Meeting";
import Invoice from "../../../Admin/Invoice";
import Package from "../../../Admin/Package";
import ITChecklist from "../../../Admin/ITChecklist";
import { storeUserServices } from "./../../../../Redux/userSlice";
import { useDispatch } from "react-redux/es/exports";
import { getAllService } from "../../../../Redux/serviceSlice";
import {
  fillClientForm,
  getClientDetail,
} from "../../../../Redux/clientFormSlice";
import { useLocation } from "react-router-dom";

// UserType
// admin = 1
// client = 2
// BDUser = 3
// ITEmployee = 4
// manager = 5

const { Step } = Steps;
const steps = [
  {
    key: 1,
    title: "Client Information",
    //content: <Information />,
    content2: (ref, showModal) => (
      <Information refs={ref} showModal={showModal} />
    ),
  },
  {
    key: 2,
    title: "Required Services",
    //content: <Tabbbing />,
    content2: (ref, showModal, setCurrent) => (
      <Tabbbing refs={ref} setCurrent={setCurrent} />
    ),
  },
  {
    key: 3,
    title: "Required Trial Job",
    //content: <Job />,
    content2: (ref, showModal, setCurrent, done, setSteps1, steps1, steps) => (
      <Job
        refs={ref}
        setCurrent={setCurrent}
        done={done}
        setSteps1={setSteps1}
        steps1={steps1}
        steps={steps}
      />
    ),
  },
  {
    key: 4,
    title: "NDNC",
    //content: <Ndnc />,
    content2: (ref, showModal, setCurrent, pageName) => (
      <Ndnc
        refs={ref}
        showModal={showModal}
        setCurrent={setCurrent}
        pageName={pageName}
      />
    ),
  },
  {
    key: 5,
    title: "Trial Service Request",
    //content: <Trial />,
    content2: (ref, showModal, setCurrent) => (
      <Trial refs={ref} showModal={showModal} setCurrent={setCurrent} />
    ),
  },
  {
    key: 6,
    title: "Package",
    //content: <Package />,
    content2: (ref, showModal, setCurrent) => (
      <Package refs={ref} showModal={showModal} setCurrent={setCurrent} />
    ),
  },
  {
    key: 7,
    title: "SLA",
    //content: <SLA />,
    content2: (ref, showModal, setCurrent, pageName) => (
      <Ndnc refs={ref} setCurrent={setCurrent} pageName={pageName} />
    ),
  },
  {
    key: 8,
    title: "Team Member",
    //content: <Team />,
    content2: (ref, showModal, setCurrent) => (
      <Team refs={ref} setCurrent={setCurrent} />
    ),
  },
  // {
  //   key: 9,
  //   title: "Meeting",
  //   //content: <Meeting />,
  //   content2: (ref, showModal) => <Meeting refs={ref} showModal={showModal} />,
  // },
  {
    key: 10,
    title: "IT Checklist",
    content2: (ref, showModal, setCurrent) => (
      <ITChecklist refs={ref} setCurrent={setCurrent} />
    ),
  },
  {
    key: 11,
    title: "Invoice",
    content2: (ref, showModal, setCurrent) => (
      <Invoice refs={ref} setCurrent={setCurrent} />
    ),
  },
  {
    key: 12,
    title: "Review Form",
    //content: <Review />,
    content2: (ref, showModal, setCurrent, done) => (
      <Review refs={ref} setCurrent={setCurrent} done={done} />
    ),
  },
];

const AddClient = () => {
  // STAPER
  const [current, setCurrent] = useState(0);
  const [steps1, setSteps1] = useState(steps);
  const [userType, setUserType] = useState(
    JSON.parse(localStorage.getItem("userType"))
  );

  const ref = useRef(null); // ref => { current: null }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [error, setError] = useState("");

  const [serviceList, setServiceList] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [allData, setAllData] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    //console.log(location);
    //console.log(current);
    if (location?.state?.pageType === "add") {
      localStorage.removeItem("clientID");
      localStorage.removeItem("userID");
    } else {
      localStorage.setItem("userID", location?.state?.userId);
      if (location?.state?.clientId) {
        localStorage.setItem("clientID", location?.state?.clientId);
      }
    }
    //setLocation((prev) => !prev);
  }, []);

  //fetching client detail
  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          setIsLoading(false);
          setAllData(res.payload.data);
          if (res?.payload?.data?.isTrialJob === false) {
            //console.log(steps1);
            let abc = steps1.concat();
            setSteps1([...abc.slice(0, 4), ...abc.slice(5, abc.length)]);
          }
          if (res?.payload?.data?.mainStepNo) {
            setCurrent(res?.payload?.data?.mainStepNo - 1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid && current === 0) {
      setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          setIsLoading(false);
          //console.log(res.payload.data);
          setAllData(res.payload.data);
          if (
            res?.payload?.data?.mainStepNo > 1 ||
            res?.payload?.data.selectedService.length
          ) {
            setSelectedServices(res.payload.data.selectedService);
            setSelectedValue(
              res.payload.data.selectedService.map(
                (data) => data.selectedServiceID
              )
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [current]);

  //getting allService List
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllService())
      .then((res) => {
        //console.log(res.payload);
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setServiceList(
            res.payload.data.serviceList.map((data, index) => {
              return { key: index, id: data._id, title: data.title };
            })
          );
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
    setError("");
  };

  const handleOk = () => {
    if (selectedValue.length) {
      setIsModalVisible(false);
      setError("");
      const tarray = selectedValue.map((data) => {
        const tdata = serviceList.find((data1) => data === data1.id);
        return tdata;
      });
      dispatch(storeUserServices(tarray));
      //console.log(tarray);

      let newAllData;
      if (selectedServices.length && allData) {
        //finding new services
        let newtarray = tarray.filter((data) => {
          if (
            selectedServices.find(
              (data1) => data.id === data1.selectedServiceID
            )
          ) {
            return false;
          } else {
            return true;
          }
        });
        //console.log("newtarray", newtarray);

        //filtering old services
        let newselectedServices = selectedServices.filter((data) => {
          if (tarray.find((data1) => data1.id === data.selectedServiceID)) {
            return true;
          } else {
            return false;
          }
        });
        newselectedServices = [
          ...newselectedServices,
          ...newtarray.map((data) => {
            return { selectedServiceID: data.id, serviceTitle: data.title };
          }),
        ];
        //console.log(newselectedServices);

        newAllData = {
          ...allData,
          mainStepNo: 1,
          subStepNo: 0,
          status: 0,
          selectedService: newselectedServices,
        };
      }
      //console.log(newAllData);

      if (newAllData) {
        dispatch(fillClientForm(newAllData))
          .then((res) => {
            //console.log(res.payload.data);
            if (res.payload.statusCode === 200) {
              setCurrent(current + 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCurrent(current + 1);
      }
    }
    setError("Please Select at least One service");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setError("");
  };

  const onChange1 = (value) => {
    setCurrent(value);
    // let clientid = localStorage.getItem("clientID");
    // if (clientid) {
    //   if (allData?.mainStepNo < value) {
    //     message.warning("Please Fill Current Step");
    //   } else {
    //     //console.log(value);
    //     if (current === 0 && value === 1) {
    //       showModal();
    //     } else {
    //       setCurrent(value);
    //     }
    //   }
    // } else {
    //   let userId = localStorage.getItem("userID");
    //   console.log(userId);
    //   if (userId) {
    //     if (current === 0 && value === 1) {
    //       showModal();
    //     } else if (current === 1 && value === 0) {
    //       setCurrent(value);
    //     } else {
    //       message.warning("Please Fill Current Step");
    //     }
    //   } else {
    //     message.warning("Please Fill Current Step");
    //   }
    // }
  };

  useEffect(() => {
    const newarray = steps1.concat();
    newarray.splice(3);
    if (userType === 3) {
      setSteps1(newarray);
    }
  }, []);

  const next = () => {
    if (current === 0) {
      ref.current.submit();
    } else {
      ref.current.submit();
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const handleChange = (value, option) => {
    //console.log(value, option);
    setSelectedValue(value);
    if (option.length) {
      setError("");
    }
  };

  const handleCurrentPage = (current) => {
    //console.log(current);
    //console.log(steps1.length);
    if (
      (steps1.length === 10 && current === 5) ||
      (steps1.length === 11 && current === 6)
    ) {
      return steps1[current].content2(ref, showModal, setCurrent, "SLA");
    } else if (current === 3) {
      return steps1[current].content2(ref, showModal, setCurrent, "NDNC");
    } else {
      let done;
      if (current + 1 === steps1.length) {
        done = true;
      } else {
        done = false;
      }
      return steps1[current].content2(
        ref,
        showModal,
        setCurrent,
        done,
        setSteps1,
        steps1,
        steps
      );
    }
  };

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          {/* <Breadcrumbs content="Add Client" /> */}

          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Select Services Required*</p>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              onChange={handleChange}
              //defaultValue={selectedServices}
              value={selectedValue}
            >
              {serviceList &&
                serviceList?.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    {data.title}
                  </Select.Option>
                ))}
            </Select>
            <p style={{ color: "red" }}>{error}</p>
          </Modal>

          <div className="event-wrap">
            <div className="step-box">
              <Steps
                direction="vertical"
                onChange={onChange1}
                current={current}
              >
                {steps1.map((item) => (
                  <Step key={item.key} title={item.title} />
                ))}
              </Steps>

              {isLoading ? (
                <Spin />
              ) : (
                <div className="steps-content">
                  {handleCurrentPage(current)}
                  {/* {steps1[current].content2(ref, showModal, setCurrent)} */}
                </div>
              )}

              <div className="steps-action">
                {current < steps1.length - 1 && (
                  <Button
                    type="primary"
                    className="btn-primary"
                    onClick={() => next()}
                  >
                    Next
                  </Button>
                )}
                {current === steps1.length - 1 && (
                  <Button
                    className="btn-primary"
                    type="primary"
                    //onClick={() => message.success("Processing complete!")}
                    onClick={() => next()}
                  >
                    Done
                  </Button>
                )}
                {current > 0 && (
                  <Button
                    className="btn-secondary"
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AddClient;
