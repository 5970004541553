import {
  Button,
  Form,
  Input,
  Collapse,
  Layout,
  Modal,
  Row,
  Select,
  Table,
  DatePicker,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import {
  DeleteTwoTone,
  EditTwoTone,
  FilterTwoTone,
  FlagTwoTone,
  CalendarTwoTone,
  IdcardTwoTone,
  SettingTwoTone,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./client.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE } from "../../../constants/api";
import MDelete from "../../../components/common/Delete";
import { getAllService } from "../../../Redux/serviceSlice";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const Client = () => {
  const [client, setClient] = useState([]);

  const [totalRecords, setTotalRecords] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [deleted, setDeleted] = useState(false);

  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sort, setSort] = useState([
    { column: "companyName", order: "asc" },
    { column: "personName", order: "asc" },
    { column: "phoneNumber", order: "asc" },
    { column: "taskStatus", order: "asc" },
    { column: "createdAt", order: "asc" },
  ]);

  const [options, SetOptions] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceTypeList, setServiceTypeList] = useState([]);

  const [status, setStatus] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [filterData, setFilterData] = useState();
  const [id, setId] = useState();

  const dispatch = useDispatch();

  const [ModalVisible, setModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  let baseURL = "https://clienton-boarding.s3.amazonaws.com/logo/"
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let tdata = {
      startDate: dates?.[0],
      endDate: dates?.[1],
      selectedService,
      selectedServiceType,
      status,
    };
    setFilterData(tdata);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    let object;
    if (sortingColumn) {
      object = sort?.find((data) => data.column === sortingColumn);
    }
    const data = {
      limit,
      skip,
      search,
      column: object?.column || "",
      order: object?.order || "",
    };
    let newData;
    if (filterData) {
      newData = { ...data, ...filterData };
    }
    newData = newData ? newData : data;

    axios
      .post(`${API_BASE}v1/clientForm/getClientList`, newData)
      .then((res) => {
        //console.log(res.data.data?.[0]);
        if (res.data.statusCode === 200) {
          setClient(
            res.data.data?.[0]?.list.map((data) => {
              return { ...data, key: data._id };
            })
          );
          setTotalRecords(res.data.data?.[0]?.total_records);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [limit, skip, search, sort, filterData, deleted]);

  //getting allService List
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllService())
      .then((res) => {
        //console.log(res.payload);
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setServiceList(
            res.payload.data.serviceList.map((data, index) => {
              return { key: index, id: data._id, title: data.title };
            })
          );
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  //getAll serviceType
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_BASE}v1/service-type/getAllServiceType`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          //console.log(res.data.data.serviceTypeList);
          setServiceTypeList(res.data.data.serviceTypeList);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const showModal1 = (id) => {
    setId(id);
    setModalVisible(true);
  };

  const handleSort = (value) => {
    setSortingColumn(value);
    setSort(
      sort.map((data) => {
        if (data.column === value) {
          return { ...data, order: data.order === "asc" ? "desc" : "asc" };
        } else {
          return data;
        }
      })
    );
  };

  const columns = [
    {
      title: "Client Image",
      dataIndex: "logo",
      key: "image",
      render: (row) => (
        <div>
          {row ? (
            <img
              src={`${baseURL}` + row}
              alt="Client Image"
              width="50"
              height="50"
            />
          ) : (    
            <p>NA</p>
          )}
        </div>
      ),
    },                
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("companyName")}
        >
          Company Name
          {sort.find((data) => data.column === "companyName")?.order ===
          "asc" ? (
            <CaretUpOutlined />
          ) : (
            <CaretDownOutlined />
          )}
        </div>
      ),
      dataIndex: "companyName",
      key: "company",
    },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("personName")}
        >
          Person Name{" "}
          {sort.find((data) => data.column === "personName")?.order ===
          "asc" ? (
            <CaretUpOutlined />
          ) : (
            <CaretDownOutlined />
          )}
        </div>                                                            
      ),
      dataIndex: "personName",
      key: "person",                        
    },

    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("phoneNumber")}
        >
          Phone Number{" "}
          {sort.find((data) => data.column === "phoneNumber")?.order ===
          "asc" ? (
            <CaretUpOutlined />
          ) : (                                                    
            <CaretDownOutlined />
          )}
        </div>
      ),
      dataIndex: "phoneNumber",
      key: "phone",
    },
    {
      title: "Service Selected",
      dataIndex: "service",
      key: "service",
      render: (row, record) => (
        <div>
          {record?.serviceSelected?.length ? (
            record?.serviceSelected?.join(", ")
          ) : (
            <p>NA</p>
          )}                        
        </div>
      ),
    },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("taskStatus")}
        >
          Task Status{" "}
          {sort.find((data) => data.column === "taskStatus")?.order ===
          "asc" ? (
            <CaretUpOutlined />
          ) : (
            <CaretDownOutlined />
          )}
        </div>
      ),
      dataIndex: "taskStatus",
      key: "task",
      render: (row) => {
        if (row === 1) {
          return <div>Service Added</div>;
        } else if (row === 2) {
          return <div>NDNC Sent</div>;
        } else if (row === 3) {
          return <div>NDNC Sign</div>;
        } else if (row === 4) {
          return <div>Trial Request Receive</div>;
        } else if (row === 5) {
          return <div>Trial started</div>;
        } else if (row === 6) {
          return <div>Trial Completed</div>;
        } else if (row === 7) {
          return <div>SLA Sent</div>;
        } else if (row === 8) {
          return <div>SLA Received</div>;
        } else if (row === 9) {
          return <div>Team member assigned</div>;
        } else if (row === 10) {
          return <div>meeting scheduled</div>;
        } else if (row === 11) {
          return <div>IT Checklist Completed</div>;
        } else if (row === 12) {
          return <div>Invoice generated</div>;
        } else if (row === 13) {
          return <div>Invoice Sent</div>;
        } else if (row === 14) {
          return <div>Payment Received</div>;
        } else if (row === 15) {
          return <div>Package Selected</div>;
        } else if (row === 16) {
          return <div>ACH Left</div>;
        } else if (row === 17) {
          return <div>ACH Done</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("createdAt")}
        >
          Created At{" "}
          {sort.find((data) => data.column === "createdAt")?.order === "asc" ? (
            <CaretUpOutlined />
          ) : (
            <CaretDownOutlined />
          )}
        </div>
      ),
      dataIndex: "createdAt",
      key: "startDate",
      render: (row) => <div>{row?.split?.("T")?.[0]}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: "view",
      render: (row, record) => (
        <ul className="action-col">
          <li>
            <Link
              to="/client/add_BdUser"
              state={{
                pageType: "edit",
                clientId: record?._id,
                userId: record?.userID,
              }}
            >
              <EditTwoTone />
            </Link>
          </li>
          <li>
            <DeleteTwoTone onClick={() => showModal1(record._id, "delete")} />
          </li>
        </ul>
      ),
    },
  ];

  // accodian
  const [expandIconPosition, setExpandIconPosition] = useState("start");

  const Flag = () => (
    <FlagTwoTone
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
  const date = () => (
    <CalendarTwoTone
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
  const setting = () => (
    <SettingTwoTone
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
  <IdcardTwoTone />;
  const team = () => (
    <IdcardTwoTone
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );

  useEffect(() => {
    let array = [
      "Service Added",
      "NDNC Sent",
      "NDNC Sign",
      "Trial Request Receive",
      "Trial started",
      "Trial Completed",
      "SLA Sent",
      "SLA Received",
      "Team member assigned",
      "meeting scheduled",
      "IT Checklist Completed",
      "Invoice generated",
      "Invoice Sent",
      "Payment Received",
      "Package Selected",
      "ACH Left",
      "ACH Doned",
    ];

    SetOptions(
      array.map((data, index) => {
        return { name: data, id: index + 1 };
      })
    );
  }, []);

  const handleStatus = (value) => {
    setStatus(value);
  };
  const handleService = (value) => {
    setSelectedService(value);
  };
  const handleServiceType = (value) => {
    setSelectedServiceType(value);
  };

  const handleDates = (dates, dateStrings) => {
    //console.log(dates, dateStrings);
    setDates(dateStrings);
  };

  const handleRadio = (e) => {
    //console.log(e.target.value);
    //setDates(dateStrings);
  };

  console.log(deleted)
  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <div className="filter-wrap  event-wrap">
            <h2 className="title">Lists of Clients</h2>
            <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="/client/add_BdUser" state={{ pageType: "add" }}>
                  Add New
                </Link>
              </Button>
            </Row>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <Row className="search">
                <div className="col-lg-3 col-sm-4 pl-0">
                  <Input
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4">
                  <Row align="middle" justify="end">
                    <div className="col-3 pl-0">
                      <div onClick={showModal} style={{ cursor: "pointer" }}>
                        <h5
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "10px",
                          }}
                        >
                          Filters:
                          <FilterTwoTone style={{ marginLeft: "5px" }} />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
              <div className="common-table">
                <Table
                  bordered
                  dataSource={client}
                  columns={columns}
                  scroll={{ x: 1199 }}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalRecords,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>

      {/* filter modal*/}
      <Modal
        title="Filters"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            className="cancle-btn btn-secondary"
            onClick={() => setIsModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button className="submit-btn btn-primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <div className="filter-inner">
          {/* <h4>All</h4> */}
          <div>
            <Collapse
              //onChange={onChange}
              expandIconPosition={expandIconPosition}
            >
              <Panel header="Status" key="1" extra={Flag()}>
                <div>
                  <Select
                    placeholder="Select Status"
                    mode="multiple"
                    allowClear
                    onChange={handleStatus}
                  >
                    {options?.map((data) => (
                      <Select.Option value={data.id}>{data.name}</Select.Option>
                    ))}
                  </Select>
                </div>
              </Panel>
              <Panel header="Created Date" key="2" extra={date()}>
                <div>
                  <Form.Item className="mb-0">
                    <RangePicker onChange={handleDates} />
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Service" key="3" extra={setting()}>
                <div>
                  <Select
                    placeholder="Select Status"
                    mode="multiple"
                    allowClear
                    onChange={handleService}
                  >
                    {serviceList?.map((data) => (
                      <Select.Option value={data.id}>
                        {data.title}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Panel>
              <Panel header="Service Type " key="4" extra={setting()}>
                <div>
                  <Select
                    placeholder="Select Status"
                    mode="multiple"
                    allowClear
                    onChange={handleServiceType}
                  >
                    {serviceTypeList?.map((data) => (
                      <Select.Option value={data._id}>
                        {data.title}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Panel>
              {/* <Panel header="Team Member" key="5" extra={team()}>
                <div>
                  <Select>
                    <Select.Option value="demo">Demo</Select.Option>
                  </Select>
                </div>
              </Panel> */}
            </Collapse>
            {/* <div className="mt-3">
              <label> Status </label>
              <Radio.Group onChange={handleRadio}>
                <Radio value="Active"> Active </Radio>
                <Radio value="InActive">InActive</Radio>
              </Radio.Group>
            </div> */}
          </div>
        </div>
      </Modal>

      {ModalVisible ? (
        <MDelete
          setModalVisible={setModalVisible}
          ModalVisible={ModalVisible}
          content1={"Are You sure you want to delete ?"}
          content2={"Your Action can't be undone"}
          status={"delete"}
          id={id}
          setDeleted={setDeleted}
          data={client}
          setData={setClient}
          setTotalData={setTotalRecords}
          api="clientForm/deleteClient"
        />
      ) : null}
    </>
  );
};

export default Client;
