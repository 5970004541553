import React from "react";
import { Button, Modal, message } from "antd";
import { useDispatch } from "react-redux/es/exports";
import { deleteUser, changeUserStatus } from "../../../Redux/userSlice";
import axios from "axios";
import { API_BASE } from "../../../constants/api";

const MDelete = ({
  setModalVisible,
  ModalVisible,
  id,
  content1,
  content2,
  data,
  setData,
  status,
  setTotalData,
  api,
  setDeleted
}) => {
  const dispatch = useDispatch();

  //console.log(data);
  const handleOk = () => {
    if (status === "delete") {
      axios
        .delete(`${API_BASE}v1/${api}/${id}`)
        .then((res) => {
          //console.log(res.payload);
          if (res.data.statusCode === 200) {
            message.success(res.data.message);
            setData(
              data.filter((data) => {
                if (data._id === id) return false;
                else return true;
              })
            );
            setDeleted(prev => !prev)
            setTotalData((preValue) => preValue - 1);
            setModalVisible(false);
          } else {
            message.error(res.data.message);
            setModalVisible(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setModalVisible(false);
        });
    } else {
      const data2 = {
        userId: id,
        isActive: status,
      };
      dispatch(changeUserStatus(data2))
        .then((res) => {
          //console.log(res.payload);
          //console.log(data);
          if (res.payload.statusCode === 200) {
            message.success(res.payload.message);
            setData(
              data.map((data1) => {
                if (data1._id === id) {
                  return {
                    ...data1,
                    isActive: !data1.isActive,
                  };
                } else {
                  return data1;
                }
              })
            );
            setModalVisible(false);
          } else {
            message.error(res.payload.message);
            setModalVisible(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setModalVisible(false);
        });
    }
  };

  return (
    <>
      <Modal
        visible={ModalVisible}
        //onOk={editShiftInfoOk}
        onCancel={() => setModalVisible(false)}
        centered={true}
        className="common-modal edit-modal"
        footer={[
          <Button className="cancle-btn btn-secondary" onClick={() => setModalVisible(false)}>
            No
          </Button>,
          <Button className="submit-btn btn-primary" onClick={handleOk}>
            Yes
          </Button>,
        ]}
      >
        <div className="modal-body">
          <div className="desc">
            {/* <span className="icon"><ExclamationCircleOutlined /></span> */}
            <h2 className="title">
              {content1 ? content1 : "Are you sure want to delete?"}
            </h2>
            <p>{content2 ? content2 : "Your action can't be undone"}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default MDelete;
