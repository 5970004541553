import { Breadcrumb, Button, Dropdown, Input, Layout, Row, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import {
  ExclamationCircleOutlined,
  EyeFilled,
  DeleteTwoTone,
  EditTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import Breadcrumbs from "../../../components/common/Breadcrumb/index";
import PlusIcon from "../../../SVG/Plus";
import "./client.scss";
import { Menu, Space } from "antd";
import Down from "../../../SVG/Down";
import { Link } from "react-router-dom";

const menu = (
  <Menu
    items={[
      {
        label: <a href="https://www.antgroup.com">Active</a>,
        key: "0",
      },
      {
        label: <a href="https://www.aliyun.com">InActive</a>,
        key: "1",
      },
      // {
      //   type: 'divider',
      // },
      // {
      //   label: '3rd menu item',
      //   key: '3',
      // },
    ]}
  />
);

const ITClient = () => {
  const [editInfoVisible, setEditInfoVisible] = useState(false);
  const [EditInfoLoading, setEditInfoLoading] = useState(false);

  const showEditInfo = () => {
    setEditInfoVisible(true);
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  });

  const editShiftInfoOk = () => {
    setEditInfoLoading(true);
    setTimeout(() => {
      setEditInfoLoading(false);
      setEditInfoVisible(false);
    }, 250);
  };

  // modal-2
  const [editInfoVisible2, setEditInfoVisible2] = useState(false);
  //cons

  // modal-2
  const showEditInfo2 = () => {
    setEditInfoVisible2(true);
  };

  const datasource = [
    {
      key: "1",
      image: (
        <img
          width="40"
          height="40"
          src="assets/images/ug-front-1.png"
          alt="image"
          style={{ border: "1px solid #1890FF", borderRadius: 50 }}
        />
      ),
      company: "Public Company",
      person: "Frank",
      IT:'Frank',
      phone: "+91 55554444",
      service: "Tax,Audit",
      task: "Client Created",
      startDate: "10/12/2022",
      endDate: "10/12/2022",
      status: "",
      action: "",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Person Name",
      dataIndex: "person",
      key: "person",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "IT Checklist",
      dataIndex: "IT",
      key: "IT",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Created Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => a.fname - b.fname,
        multiple: 3,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: "view",
      render: (row) => (
        <ul className="action-col btns-col">
          {/* <li>
            <button className="common-btn" onClick={showEditInfo3}><EyeFilled /></button>
          </li> */}
          <li>
            <Link to="/client_it/view_checklist" className="btn-primary" onClick={showEditInfo2}>
              {/* <EditTwoTone /> */}
              View IT Checklist
            </Link>
          </li>
          {/* <li>
            <button className="common-btn" onClick={showEditInfo}>
              <DeleteTwoTone />
            </button>
          </li> */}
        </ul>
      ),
    },
  ];

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs />

          <div className="filter-wrap  event-wrap">
            <h2 className="title">Lists of Clients</h2>
            {/* <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="/client/add_BdUser">Add New</Link>
              </Button>
            </Row> */}
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              {/* <Row className="search">
                <div className="col-lg-3 col-sm-4">
              <Dropdown
              className="status-dropdown"
              overlay={menu}
              trigger={["click"]}
            >
              <a className="des_dropdown" onClick={(e) => e.preventDefault()}>
                <Space>
                  Status
                  <Down />
                </Space>
              </a>
            </Dropdown>
                </div>
                <div className="col-lg-3 col-sm-4">
                  <Input placeholder="Search here" />
                </div>
              </Row> */}
              <div className="common-table">
                <Table
                  bordered
                  dataSource={datasource}
                  columns={columns}
                  scroll={{ x: 870 }}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>

          {/* <div className="view-btn box-btn plus_icon">
            <Dropdown
              className="status-dropdown"
              overlay={menu}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Status
                  <Down />
                </Space>
              </a>
            </Dropdown>
          </div> */}
        </Content>
      </Layout>
    </>
  );
};

export default ITClient;
