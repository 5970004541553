import React, { useState } from "react";
import "./sidebar.scss";
import { Layout, Menu } from "antd";
import User from "../../../SVG/User";
import Dashboard from "../../../SVG/Dashboard";
import { Link } from "react-router-dom";
import Menuicon from "../../../SVG/Menuicon";
import FormOutlined from "../../../SVG/FormOutlined";
import IT from "../../../SVG/IT";
import TeamMember from "../../../SVG/TeamMember";
import Time from "../../../SVG/Time";
import SubMenu from "antd/lib/menu/SubMenu";
import { ProfileOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const Sidebar = () => {
  const userType = JSON.parse(localStorage.getItem("userType"));

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  // UserType
  // admin = 1
  // client = 2
  // BDUser = 3
  // ITEmployee = 4
  // manager = 5
  // headApprover=6
  // teamMember=7

  const handleMenu = () => {
    if (userType === 1 || userType === 6) {
      return (
        <Menu className="sidebar" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="1" icon={<Dashboard />}>
            <Link to="/dashboard"> Dashbaord </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<User />}>
            <Link to="/bdUser"> BD User </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<Dashboard />}>
            <Link to="/headApprover">Head Approver </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<TeamMember />}>
            <Link to="/teamMember">Team Member</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<IT />}>
            <Link to="/itUser">IT User</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<FormOutlined />}>
            <Link to="/manager">Manager</Link>
          </Menu.Item>
          <SubMenu
            key="sub1"
            icon={<ProfileOutlined />}
            title="Data"
            className="submenu sidebar"
          >
            <Menu.Item key="7.1">
              <Link to="/data/service">Service</Link>
            </Menu.Item>
            <Menu.Item key="7.2">
              <Link to="/data/service_type">Service Type</Link>
            </Menu.Item>
            <Menu.Item key="7.3">
              <Link to="/data/sub_service">Service Sub Type</Link>
            </Menu.Item>
            <Menu.Item key="7.4">
              <Link to="/data/expertise" state={{ page: "expertise" }}>
                Expertise Required
              </Link>
            </Menu.Item>
            <Menu.Item key="7.5">
              <Link to="/data/software" state={{ page: "software" }}>
                Software Required
              </Link>
            </Menu.Item>
            <Menu.Item key="7.6">
              <Link to="/data/industry" state={{ page: "industry" }}>
                Industry
              </Link>
            </Menu.Item>
          </SubMenu>
          {/* bd user */}
          <Menu.Item key="8" icon={<User />}>
            <Link to="/client">Clients </Link>
          </Menu.Item>
          <Menu.Item key="9" icon={<Time />}>
            <Link to="/schedule_meeting">Schedule Meeting</Link>
          </Menu.Item>

          <Menu.Item key="27" icon={<FormOutlined />}>
            <Link to="/manage_packge">Packages</Link>
          </Menu.Item>
          {/* it user */}
          {/* <Menu.Item key="10" icon={<User />}>
            <Link to="/client_it">Clients</Link>
          </Menu.Item> */}
          <Menu.Item key="16" icon={<FormOutlined />}>
            <Link to="/faq">FAQ</Link>
          </Menu.Item>

          {/* <Menu.Item key="19" icon={<FormOutlined />}>
            <Link to="/package">Package</Link>
          </Menu.Item> */}
          <Menu.Item key="17" icon={<FormOutlined />}>
            <Link to="/email">Email Template</Link>
          </Menu.Item>
          

          <SubMenu
            key="sub2"
            icon={<ProfileOutlined />}
            title="Report"
            className="submenu sidebar"
          >
            <Menu.Item key="18.1">
              <Link to="/report/client_status_log">Client Status Log</Link>
            </Menu.Item>
            <Menu.Item key="18.2">
              <Link to="/report/team_allocation_log">Team Allocation Log</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      );
    } else if (userType === 2) {
      return (
        <Menu className="sidebar" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="8" icon={<Time />}>
            <Link to="/schedule_meeting">Schedule Meeting (BD)</Link>
          </Menu.Item>
        </Menu>
      );
    } else if (userType === 3) {
      return (
        <Menu className="sidebar" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="1" icon={<Dashboard />}>
            <Link to="/dashboard"> Dashbaord </Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<User />}>
            <Link to="/client">Clients (BD)</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<Time />}>
            <Link to="/schedule_meeting">Schedule Meeting (BD)</Link>
          </Menu.Item>
        </Menu>
      );
    } else if (userType === 4) {
      return (
        <Menu className="sidebar" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="7" icon={<User />}>
            <Link to="/client">Clients (BD)</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<Time />}>
            <Link to="/schedule_meeting">Schedule Meeting (BD)</Link>
          </Menu.Item>
        </Menu>
      );
    } else if (userType === 5) {
      return (
        <Menu className="sidebar" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="1" icon={<Dashboard />}>
            <Link to="/dashboard"> Dashbaord </Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<User />}>
            <Link to="/client">Clients (BD)</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<Time />}>
            <Link to="/schedule_meeting">Schedule Meeting (BD)</Link>
          </Menu.Item>
        </Menu>
      );
    }
  };

  return (
    <>
      <Sider
        className="sidebar"
        trigger={<Menuicon className="sidebar" />}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width="250"
      >
        {handleMenu()}
      </Sider>
    </>
  );
};

export default Sidebar;
