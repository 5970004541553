import { Breadcrumb, Button, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CommonMeeting from "../../../../components/common/CommonMeet";
import {
  getMeetingDetails,
  getListOfUpcommingMeeting,
} from "../../../../Redux/scheduleMeeting";
import { getListUsers } from "../../../../Redux/userSlice";

const Meeting = () => {
  const dispatch = useDispatch();
  const meetingId = useParams();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
  });
  const [clientList, setClientList] = useState([]);
  const [meetingData, setMeetingData] = useState();
  const [apiToggle, setApiToggle] = useState(false);

  useEffect(() => {
    dispatch(getListUsers({ userType: [2] }))
      .then((res) => {
        setClientList(res.payload.data.list);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    dispatch(getMeetingDetails(meetingId?.id))
      .then((res) => {
        if (res.payload.statusCode == 200) {
          setMeetingData(res.payload.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, [apiToggle, meetingId?.id]);
  //console.log(clientList)
  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumb />

          <div className="filter-wrap event-wrap">
            <h2 className="title">Edit Meeting</h2>
            <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="button" className="btn-primary">
                <Link to="/schedule_meeting/view_meeting">Back</Link>
              </Button>
            </Row>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <CommonMeeting
                clientList={clientList}
                type="edit"
                meetingId={meetingId}
                meetingData={meetingData}
                apiToggle={apiToggle}
                setApiToggle={setApiToggle}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Meeting;
