import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {};

export const addEmail = createAsyncThunk(
    "mailTemplate/addMailTemplate",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/mailTemplate/addMailTemplate`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const listMail = createAsyncThunk(
    "mailTemplate/getMailTemplateList",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/mailTemplate/getMailTemplateList`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const deleteMail = createAsyncThunk(
    "mailTemplate/deleteMailTemplate",
    async (data) => {
        try {
            const response = await axios.delete(
                `${API_BASE}v1/mailTemplate/deleteMailTemplate/${data}`
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const getMailDetails = createAsyncThunk(
    "mailTemplate/getMailTemplateById",
    async (data) => {
        try {
            const response = await axios.get(
                `${API_BASE}v1/mailTemplate/getMailTemplateById/${data}`
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const updateMail = createAsyncThunk(
    "mailTemplate/updateMailTemplate",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/mailTemplate/updateMailTemplate/${data?.mailTemplateId}`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const emailSlice = createSlice({
    name: "emailSlice",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
    },
});

export default emailSlice.reducer;
