import {
  Button,
  Input,
  Collapse,
  Layout,
  Row,
  Table,
  DatePicker,
  Tag,
  Space,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
// import {
//   DeleteTwoTone,
//   EditTwoTone,
//   FilterTwoTone,
//   FlagTwoTone,
//   CalendarTwoTone,
//   IdcardTwoTone,
//   SettingTwoTone,
//   CaretUpOutlined,
//   CaretDownOutlined,
// } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./client.scss";
import { Link } from "react-router-dom";
import { getClientLogList } from "../../../Redux/reportSlice";
import moment from "moment";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const TeamLog = () => {
  const dispatch = useDispatch();

  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);

  const [users, setUsers] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [clientSearch, setClientSearch] = useState();
  const [userSearch, setUserSearch] = useState();
  const [page, setPage] = useState(1);
  const [column, setColumn] = useState();
  const [order, setOrder] = useState();
  const [reload, setReload] = useState(false);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], "days") > 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 7;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const columns = [
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: true,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      sorter: true,
    },
    {
      title: "Previously Assigned Team Member Name",
      dataIndex: "previousAssignedTeamMemberName",
      key: "previousAssignedTeamMemberName",
      sorter: true,
    },
    {
      title: "Newly Assigned Team Member Name",
      dataIndex: "newAssignedTeamMemberName",
      key: "newAssignedTeamMemberName",
      sorter: true,
    },
    {
      title: "Action Date",
      // dataIndex: "est",
      key: "actionDate",
      render: (row) => moment(row?.actionDate).format("YYYY-MM-DD"),
    },
  ];
  const data = [
    {
      key: "1",
      log: "Package Created",
      Name: "Gordon Luis",
      ByUser: "Jen (BD User)",
      actionDate: "10/12/2021",
    },
  ];
  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === "sort") {
      setColumn(sorter?.field);
      setOrder(
        sorter?.order === "ascend"
          ? "asc"
          : sorter?.order === "descend"
          ? "desc"
          : ""
      );
    } else {
      setColumn();
      setOrder();
    }
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getClientLogList({
        limit: limit,
        skip: skip,
        startDate: (value && moment(value[0])?.format("YYYY-MM-DD")) || "",
        endDate: (value && moment(value[1])?.format("YYYY-MM-DD")) || "",
        status: 2,
        clientName: clientSearch,
        byUserName: "",
        teamMember: userSearch,
        order: order,
        column: column,
      })
    )
      .then((res) => {
        setIsLoading(false);

        if (res.payload.statusCode === 200) {
          setUsers(
            res.payload.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
          setTotalUsers(res.payload.data.total_records);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [limit, skip, column, order, reload]);
  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <div className="filter-wrap  event-wrap">
            <h2 className="title">Team Allocation Log</h2>
            {/* <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="/client/add_BdUser" state={{ pageType: "add" }}>
                  Add New
                </Link>
              </Button>
            </Row> */}
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <Row style={{ paddingTop: "20px" }}>
                <div className="col-lg-2 col-sm-4 pl-0 col-div">
                  <Input
                    placeholder="Client Name"
                    onChange={(e) => {
                      setClientSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-2 col-sm-4 pl-0 col-div">
                  <Input
                    placeholder="Team Member"
                    onChange={(e) => {
                      setUserSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-2 col-sm-4 pl-0 col-div">
                  <RangePicker
                    value={hackValue || value}
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => setDates(val)}
                    onChange={(val) => setValue(val)}
                    onOpenChange={onOpenChange}
                    className="range"
                  />
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-primary btn-secondary"
                  onClick={() => {
                    setClientSearch("");
                    setUserSearch("");
                    setValue(null);
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-primary"
                  onClick={() => setReload(!reload)}
                >
                  Search
                </Button>
              </Row>
              <div className="common-table">
                <Table
                  bordered
                  columns={columns}
                  Loading={isLoading}
                  dataSource={users}
                  scroll={{ x: 870 }}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalUsers,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default TeamLog;
