import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Service from "./Service";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { getClientDetail } from "../../../../Redux/clientFormSlice";

const { TabPane } = Tabs;

const Tabbbing = (prop) => {
  let selectServicesdata1 = useSelector((state) => state.user.chekedServices);

  const [selectedService, setSelectedService] = useState(selectServicesdata1);
  const [key1, setKey1] = useState("1");

  const dispatch = useDispatch();

  function callback(key) {
    setKey1(key.toString());
  }

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    if (clientid) {
      //setIsLoading(true);
      dispatch(getClientDetail(clientid))
        .then((res) => {
          //setIsLoading(false);
          if (res.payload.statusCode === 200) {
            //console.log(res.payload.data);
            if (
              res?.payload?.data?.mainStepNo > 1 &&
              res?.payload?.data?.selectedService?.length
            ) {
              const tarray = res?.payload?.data?.selectedService?.map(
                (data, index) => {
                  return {
                    key: index,
                    id: data?.selectedServiceID,
                    title: data?.serviceTitle || index,
                  };
                }
              );
              //console.log(tarray);
              setSelectedService(tarray);
            }
          }
        })
        .catch((err) => {
          //setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    //console.log(selectedService);
    let selectServicesdata = selectedService?.map((data, index) => {
      return { ...data, key: index };
    });

    const serviceId = selectServicesdata?.find(
      (data) => data.key + 1 === parseInt(key1)
    );

    const tabData = {
      refs: prop.refs,
      setCurrent: prop.setCurrent,
      setKey1: setKey1,
      serviceId: serviceId?.id,
      serviceNo: key1,
      noOfServices: selectServicesdata?.length,
    };
    localStorage.setItem("tabData", JSON.stringify(tabData));
  }, [key1]);

  return (
    <>
      <Tabs activeKey={key1.toString()} onChange={callback}>
        {selectedService?.map((data, index) => (
          <TabPane
            tab={
              <span>
                {/* <Checkbox onChange={onChange} style={{ marginRight: "20px" }} /> */}
                {data.title}
              </span>
            }
            disabled
            key={index + 1}
          >
            <Service
              refs={prop.refs}
              setCurrent={prop.setCurrent}
              setKey1={setKey1}
              serviceId={data.id}
              serviceTitle={data.title}
              serviceNo={index + 1}
              noOfServices={selectedService.length}
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default Tabbbing;
