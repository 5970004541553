import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {};

export const addFaq = createAsyncThunk(
    "faq/addFaq",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/faq/addFaq`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const listFaq = createAsyncThunk(
    "faq/getFaqList",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/faq/getFaqList`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const deleteFaq = createAsyncThunk(
    "faq/deleteFaq",
    async (data) => {
        try {
            const response = await axios.delete(
                `${API_BASE}v1/faq/deleteFaq/${data}`
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const getFaqDetails = createAsyncThunk(
    "faq/getFaqById",
    async (data) => {
        try {
            const response = await axios.get(
                `${API_BASE}v1/faq/getFaqById/${data}`
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const updateFaq = createAsyncThunk(
    "faq/updateFaq",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/faq/updateFaq/${data?.FAQ_Id}`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const faqSlice = createSlice({
    name: "faqSlice",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
    },
});

export default faqSlice.reducer;
