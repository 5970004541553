import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  tabData: {},
};

export const fillClientForm = createAsyncThunk(
  "client/fillClientForm",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/clientForm/fillClientForm`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getClientDetail = createAsyncThunk(
  "client/getClientDetail",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE}v1/clientForm/getClientDetails/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const clientFormSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    saveTabData: (state, action) => {
      state.tabData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fillClientForm.fulfilled, (state, action) => {
      //state.user = action.payload.data;
      //state.isLoggedIn = true;
    });
    builder.addCase(getClientDetail.fulfilled, (state, action) => {
      //state.user = action.payload.data;
      //state.isLoggedIn = true;
    });
  },
});

export const { saveTabData } = clientFormSlice.actions;

export default clientFormSlice.reducer;
