import React, { useEffect, useState } from "react";
import "./package.scss";
import { Layout, Input, Button, Table, Row, message, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import MDelete from "../../../components/common/Delete";
import MExcel from "../../../components/common/excel";
import axios from "axios";
import { API_BASE } from "../../../constants/api";

const ManagePackge = () => {
  const [users, setUsers] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [id, setId] = useState();
  const [content1, setContent1] = useState();
  const [content2, setContent2] = useState();
  const [status, setStatus] = useState();

  const [column, setColumn] = useState("");
  const [order, setOrder] = useState("");
                                                                                                                                 
  useEffect(() => {
    const data = { limit, skip, search, column, order };
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/package/getPackageList`, data)
      .then((res) => {
        setIsLoading(false);
        //console.log(res.data.data);
        if (res.data.statusCode === 200) {
          //console.log(res.data.data);
          setUsers(res.data.data.list);
          setTotalUsers(res.data.data.total_records);
        } else {
          message.error(res.data.message);
        }                        
      })                                                                    
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [limit, skip, search, column, order]);

  //Modal
  const [ModalVisible, setModalVisible] = useState(false);
  const showModal = (id, status) => {
    if (status === "delete") {
      setContent1("Are you sure you want to delete package ?");
      setContent2("You action can't be undone");
      setStatus(status);
    } else {
      setContent1(`Do you want to ${status ? "Active" : "Inactive"} User ?`);
      setContent2(" ");
      setId(id);
      setStatus(status);
    }
    setId(id);
    setModalVisible(true);
  };

  const [excelInfoVisible, setexcelInfoVisible] = useState(false);
  const showExcle = () => {
    setexcelInfoVisible(true);
  };

  const handleSort = (value) => {
    setColumn(value);
    setOrder((prev) => {
      if (prev === "asc") return "desc";
      else return "asc";
    });
  };

  // table
  const columns = [
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("fullName")}
        >
          Package
        </div>
      ),
      dataIndex: "_id",
      key: "fname",
      render: (data, record) => (
        <div>
          <div>{record.serviceName.join()}</div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: "view",
      render: (row, record) => (
        <ul className="action-col">
          <li>
            <Link
              to="/manage_packge/edit"
              state={{ id: record._id }}
              className="common-btn"
            >
              <EditTwoTone />
            </Link>
          </li>
          <li>
            <a
              className="common-btn back"
              onClick={() => showModal(record._id, "delete")}
            >
              <DeleteTwoTone />
            </a>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <Layout className="site-layout-background">
      <Content className="admin-webpanel-content">
        <Breadcrumbs content="List of Manager" />

        <div className="filter-wrap  event-wrap">
          <h2 className="title"> Package </h2>
          <Row justify="end" className="btn-box">
            {/* <Button
              type="primary"
              htmlType="submit"
              className="btn-primary"
              onClick={showExcle}
            >
              Upload Excel To Add
            </Button> */}
            <Button type="primary" htmlType="submit" className="btn-primary">
              <Link to="/manage_packge/add">Add New Package</Link>
            </Button>
          </Row>
        </div>

        <div className="remove-wrap">
          <div className="event-wrap">
            <Row className="search" justify="end">
              <div className="col-lg-3 col-sm-4">
                <Input
                  placeholder="Search here"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Row>

            <div className="common-table">
              {isLoading ? (
                <Spin />
              ) : (
                <Table
                  bordered
                  columns={columns}
                  dataSource={users}
                  //dataSource={users1}
                  scroll={{ x: 870 }}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalUsers,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {/* modale */}
        <MDelete
          setModalVisible={setModalVisible}
          ModalVisible={ModalVisible}
          content1={content1}
          content2={content2}
          status={status}
          id={id}
          data={users}
          setData={setUsers}
          setTotalData={setTotalUsers}
          api="package/deletePackage"
        />
        <MExcel
          setexcelInfoVisible={setexcelInfoVisible}
          excelInfoVisible={excelInfoVisible}
        />
      </Content>
    </Layout>
  );
};

export default ManagePackge;
