import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Layout,
  Switch,
  message,
  Spin,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser, editUser, getUser } from "../../../../Redux/userSlice";

const AddUser = (prop) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const [user, setUser] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [pageName, setpageName] = useState();
  const [userType, setuserType] = useState();
  const [pageType, setpageType] = useState();
  const [link, setLink] = useState();

  useEffect(() => {
    setpageName(prop.pageName);
    setuserType(prop.userType);
    setpageType(prop.pageType);
    setLink(prop.link);
  }, [prop]);

  useEffect(() => {
    if (pageType === "Edit") {
      setIsLoading(true);
      dispatch(getUser(location.state.id))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            setUser(res.payload.data);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [pageType]);
                                                 
  useEffect(() => {
    if (pageType === "Edit") {
      if (user) {
        form.setFieldsValue({
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          email: user.email,
          isActive: user.isActive,
          designation: user.designation,
        });
      }
    }
  }, [user, pageType]);

  const onFinish = (values) => {
    //console.log(values);
                                                    
    if (pageType === "Add") {
      const newdata = { ...values, userType };

      setIsLoading(true);
      dispatch(addUser(newdata))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            message.success(res.payload.message);
            navigate("/" + link);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      const newdata = {
        ...user,
        ...values,
        userId: location.state.id,
      };

      setIsLoading(true);
      dispatch(editUser(newdata))
        .then((res) => {
          if (res.payload.statusCode === 200) {
            setIsLoading(false);
            message.success(res.payload.message);
            navigate("/" + link);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content={pageType + " " + pageName} />
          <div className="filter-wrap event-wrap">
            <h2 className="title">{pageName}</h2>
          </div>

          {isLoading ? (
            <Spin />
          ) : (
            <div className="event-wrap">
              <div className="content-wrap">
                <Form
                  className="sectionadd-form"
                  form={form}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">First Name</label>
                        <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your name!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  value &&
                                  value.search(/^[a-zA-Z]+$/) === -1
                                ) {
                                  return Promise.reject("Provide valid name");
                                }
                                if (
                                  value &&
                                  (value.length < 2 || value.length > 50)
                                ) {
                                  return Promise.reject(
                                    "name must contain at least 2 character"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">Last Name</label>
                        <Form.Item
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your name!",
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  value &&
                                  value.search(/^[a-zA-Z]+$/) === -1
                                ) {
                                  return Promise.reject("Provide valid name");
                                }
                                if (
                                  value &&
                                  (value.length < 2 || value.length > 50)
                                ) {
                                  return Promise.reject(
                                    "name must contain at least 2 character"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">
                          Phone Number (Prefix Number With Country Code)
                        </label>
                        <Form.Item
                          name="phoneNumber"
                          rules={[
                            { whitespace: true },
                            {
                              required: true,
                              message: "Please enter your phone number!",
                            },
                            () => ({
                              validator(_, value) {
                                if (value && value.search(/^\+?\d+$/) === -1) {
                                  return Promise.reject(
                                    "Provide valid Phone number"
                                  );
                                }
                                if (
                                  value &&
                                  (value.length < 10 || value.length > 16)
                                ) {
                                  return Promise.reject(
                                    "Provide valid Phone number"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Phone Number" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">Email</label>
                        <Form.Item
                          name="email"
                          rules={[
                            { type: "email" },
                            {
                              required: true,
                              message: "Please enter your email!",
                            },
                          ]}
                        >
                          {pageType === "Edit" ? (
                            <Input placeholder="Email" disabled />
                          ) : (
                            <Input placeholder="Email" />
                          )}
                        </Form.Item>
                      </div>
                    </div>

                    {userType === 7 ? (
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="label-title">Designation</label>
                          <Form.Item
                            name="designation"
                            rules={[
                              {
                                required: true,
                                message: "Please Select one!",
                              },
                            ]}
                          >
                            <Select placeholder="Select Designation">
                              <Select.Option key="1" value={3}>
                                Project Manager
                              </Select.Option>
                              <Select.Option key="2" value={1}>
                                Accountant
                              </Select.Option>
                              <Select.Option key="3" value={2}>
                                Member
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-12">
                      <div className="form-group">
                        <label className="label-title">
                          Status (Inactive/Active)
                        </label>
                        <Form.Item
                          name="isActive"
                          valuePropName="checked"
                          initialValue={true}
                        >
                          <Switch />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="view-btn">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-primary"
                        >
                          Save
                        </Button>
                        <Button
                          type="secondary"
                          htmlType="button"
                          className="btn-secondary"
                        >
                          <Link to={"/" + link}>Cancel</Link>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Content>
      </Layout>
    </>
  );
};

export default AddUser;
