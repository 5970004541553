import React, { useState } from 'react'
import {Button, Form,Input,message, InputNumber, Select, Layout, Row, Col, Radio} from 'antd';
import UploadFile from '../../../../components/layout/UploadFile';
import Breadcrumbs from '../../../../components/common/Breadcrumb';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import Password from 'antd/lib/input/Password';
import IT from '../../../../components/common/ITChecklist';

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const Checklist = () => {
    

  return (
    <>
        <Layout className="site-layout-background">
            <Content className="admin-webpanel-content">

                <Breadcrumbs />

                <div className="filter-wrap  event-wrap">
                    <h2 className="title">View IT Checklist</h2>
                </div>
                <IT />
            </Content>
        </Layout>
    </>
  )
}

export default Checklist;
