import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {};

export const getClientLogList = createAsyncThunk(
    "clientLog/getClientLogList",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/clientLog/getClientLogList`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {},

    extraReducers: (builder) => {

    },
});

export default reportSlice.reducer;
