import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Input, Layout, message, Modal, Row, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import MDelete from "../../../../components/common/Delete";
import { API_BASE } from "../../../../constants/api";

function Expertise(prop) {
  const [pageName, setPageName] = useState();
  const [content, setContent] = useState();
  const [totalContent, setTotalContent] = useState();

  const [ModalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState();

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [column, setColumn] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    const data = { limit, skip, search, column, order };

    let url;
    //console.log(pageName);
    if (pageName === "Industry") {
      url = "industry/getIndustryList";
    } else if (pageName === "Software") {
      url = "software/getSoftwareList";
    } else if (pageName === "Expertise") {
      url = "expertise/getExpertiseList";
    }

    if (pageName) {
      setIsLoading(true);
      axios
        .post(`${API_BASE}v1/${url}`, data)
        .then((res) => {
          //console.log(res.data);
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            setContent(
              res.data.data.list.map((data, index) => {
                return { ...data, key: index };
              })
            );
            setTotalContent(res.data.data.total_records);
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [limit, skip, search, column, order, pageName]);

  useEffect(() => {
    setPageName(prop.page);
  }, [prop]);

  const handleSort = (value) => {
    setColumn(value);
    setOrder((prev) => {
      if (prev === "asc") return "desc";
      else return "asc";
    });
  };

  const columns = [
    {
      //title: pageName,
      title: (
        <div style={{ cursor: "pointer" }} onClick={() => handleSort("title")}>
          {pageName}
        </div>
      ),
      dataIndex: "title",
      key: "expertise",
    },
    {
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSort("isActive")}
        >
          Account Status
        </div>
      ),
      dataIndex: "isActive",
      key: "Status",
      render: (row) => (
        <div className="active-desc">
          <div className="active">{row ? "Active" : "InActivate"}</div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: "view",
      render: (row, record) => (
        <ul className="action-col">
          <li className="/user/edit_user">
            <Link
              to={
                "/data/" +
                pageName?.charAt?.(0)?.toLowerCase() +
                pageName?.slice(1) +
                "/edit"
              }
              state={{ id: record._id }}
              className="common-btn"
            >
              <EditTwoTone />
            </Link>
          </li>
          <li>
            <DeleteTwoTone onClick={() => handleId(record._id)} />
          </li>
        </ul>
      ),
    },
  ];

  const handleId = (id) => {
    setId(id);
    setModalVisible(true);
  };

  let url;
  //console.log(pageName);
  if (pageName === "Industry") {
    url = "industry/deleteIndustry";
  } else if (pageName === "Software") {
    url = "software/deleteSoftware";
  } else if (pageName === "Expertise") {
    url = "expertise/deleteExpertise";
  }

  //console.log(url);

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content={"List of " + pageName} />

          <div className="filter-wrap  event-wrap">
            <div>
              <h2 className="title">Manage {pageName}</h2>
            </div>
            <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="#">Delete</Link>
              </Button>
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link
                  to={
                    "/data/" +
                    pageName?.charAt?.(0)?.toLowerCase() +
                    pageName?.slice?.(1) +
                    "/add"
                  }
                  state={{ pageType: "add" }}
                >
                  Add New {pageName}
                </Link>
              </Button>
            </Row>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <Row className="search" justify="end">
                <div className="col-lg-3 col-sm-4">
                  <Input
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </Row>
              <div className="common-table">
                <Table
                  bordered
                  dataSource={content}
                  columns={columns}
                  scroll={{ x: 870 }}
                  isLoading={isLoading}
                  pagination={{
                    current: page,
                    defaultPageSize: limit,
                    total: totalContent,
                    showTotal: (total) => `Total ${total} records`,
                    onChange: (page, pagesize) => {
                      setPage(page);
                      setSkip((page - 1) * pagesize);
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <MDelete
            setModalVisible={setModalVisible}
            ModalVisible={ModalVisible}
            content1="Are you sure you want to delete ?"
            content2="Your action can't be undone"
            status="delete"
            id={id}
            data={content}
            setData={setContent}
            setTotalData={setTotalContent}
            api={url}
          />
        </Content>
      </Layout>
    </>
  );
}

export default Expertise;
