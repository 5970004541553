import React, { useEffect, useState } from "react";
import { Form, Input, message, Select, Spin } from "antd";
import UploadFile from "../../../../components/layout/UploadFile";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { addUser, editUser } from "../../../../Redux/userSlice";

import axios from "axios";
import { API_BASE } from "../../../../constants/api";

const { Option } = Select;

const Information = (prop) => {
  const [form] = Form.useForm();
  const [userImage, setUserImage] = useState();
  const [user, setUser] = useState();
  const [country, setCountry] = useState();
  const [states, setStates] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const dispatch = useDispatch();

  let userId = localStorage.getItem("userID");
  
  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      axios
        .get(`${API_BASE}v1/user/getUserDetails/${userId}`)
        .then((res) => {
          setIsLoading(false);
          //console.log(res.data.data);
          if (res.data.statusCode === 200) {
            setUser(res.data.data);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        companyName: user.companyName,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        altrnatePhoneNumber: user.altrnatePhoneNumber,
        email: user.email,
        websiteURL: user.websiteURL,
        referredBy: user.referredBy,
        logo: user.logo,

        address1: user?.address?.fullAddress,
        address2: user?.address?.addressTwo,
        country: user?.address?.country,
        state: user?.address?.stateID,
        city: user?.address?.city,
        zipcode: user?.address?.zipcode,
      });
      setUserImage(user?.logo);
      setCountry(user?.address?.country);
    }
  }, [user]);

  useEffect(() => {
    if (country) {
      const data = { country };
      setIsLoading1(true);
      axios
        .post(`${API_BASE}v1/user/getListOfState`, data)
        .then((res) => {
          setIsLoading1(false);
          if (res.data.statusCode === 200) {
            //console.log(res.data.data.list)
            setStates(res.data.data.list);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading1(false);
          console.log(err);
        });
    }
  }, [country]);

  const onFinish = (values) => {
    const address = {
      fullAddress: values.address1,
      addressTwo: values.address2,
      zipcode: values.zipcode,
      city: values.city,
      stateID: values.state,
      country: values.country,
    };
    const newData = {
      ...values,
      firstName: values.firstName,
      lastName: values.lastName,
      address,
      logo: userImage,
      userType: 2,
      mainStepNo: 1,
      status: 0,
    };
    //console.log(newData);

    if (!user) {
      setIsLoading(true);
      dispatch(
        addUser({ ...newData, isCreatedBy: localStorage.getItem("user") })
      )
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            //console.log(res.payload.data);
            localStorage.setItem("userID", res.payload.data._id);
            message.success(res.payload.message);
            prop.showModal();
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      const newdata1 = {
        ...newData,
        userId: userId,
        logo: userImage ? userImage : "",
      };
      //console.log(newdata1);

      setIsLoading(true);
      dispatch(editUser(newdata1))
        .then((res) => {
          setIsLoading(false);
          if (res.payload.statusCode === 200) {
            message.success(res.payload.message);
            prop.showModal();
          } else {
            message.error(res.payload.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  function handleFieldChange1(changedValues, allValues) {
    //console.log(changedValues);
    if (changedValues.country) {
      setCountry(changedValues.country);
      form.setFieldsValue({
        state: "",
        city: "",
        zipcode: "",
      });
    }
    if (changedValues.state) {
      form.setFieldsValue({
        city: "",
        zipcode: "",
      });
    }
  }

  return (
    <>
      <Form
        ref={prop.refs}
        form={form}
        name="basic12"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={handleFieldChange1}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <div className="rows">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>
                  Client First Name
                </label>
                <Form.Item
                  name="firstName"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.search(/^[a-zA-Z]+$/) === -1) {
                          return Promise.reject("Provide valid name");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>
                  Client Last Name
                </label>
                <Form.Item
                  name="lastName"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.search(/^[a-zA-Z]+$/) === -1) {
                          return Promise.reject("Provide valid name");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>
                  Company Name
                </label>
                <Form.Item
                  name="companyName"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter your company name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.search(/^[a-zA-Z\s]*$/) === -1) {
                          return Promise.reject("Provide valid name");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>
                  Phone number (Prefix Number With Country Code)
                </label>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter phone number!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.search(/^\+?\d+$/) === -1) {
                          return Promise.reject("Provide valid Phone number");
                        }
                        if (value && (value.length < 10 || value.length > 15)) {
                          return Promise.reject("Provide valid Phone number");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>Email
                </label>
                <Form.Item
                  name="email"
                  rules={[
                    { type: "email" },
                    {
                      required: true,
                      message: "Please enter valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" disabled={user ? true : false} />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>Reffered by
                </label>
                <Form.Item
                  name="referredBy"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter name!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.search(/^[a-zA-Z\s]*$/) === -1) {
                          return Promise.reject("Provide valid name");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Referred By" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  Mobile number (Prefix Number With Country Code)
                </label>
                <Form.Item
                  name="altrnatePhoneNumber"
                  rules={[
                    { whitespace: true },
                    () => ({
                      validator(_, value) {
                        if (value && value.search(/^\+?\d+$/) === -1) {
                          return Promise.reject("Provide valid Phone number");
                        }
                        if (value && (value.length < 10 || value.length > 15)) {
                          return Promise.reject("Provide valid Phone number");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">Website URL</label>
                <Form.Item name="websiteURL" rules={[{ whitespace: true }]}>
                  <Input placeholder="Website URL" />
                </Form.Item>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>
                  Customer address
                </label>
                <Form.Item
                  name="address1"
                  rules={[
                    { whitespace: true },
                    {
                      required: true,
                      message: "Please enter your address!",
                    },
                  ]}
                >
                  <Input placeholder="Address" />
                </Form.Item>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="label-title">Address 2</label>
                <Form.Item name="address2" rules={[{ whitespace: true }]}>
                  <Input placeholder="Address 2" />
                </Form.Item>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label className="label-title">
                  <span className="required">*</span>
                  Select Country
                </label>
                <div className="select-venue">
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please Select!",
                      },
                    ]}
                  >
                    <Select
                      loading={isLoading1}
                      placeholder="Select Country"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Option key="USA" value="USA">USA</Option>
                      <Option key="Australia" value="Australia">Australia</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>

            {country ? (
              <>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="label-title">
                      <span className="required">*</span>
                      Select State
                    </label>
                    <div className="select-venue">
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please Select!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select State"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          {states?.map((data) => (
                            <Option key={data._id} value={data._id}>{data.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="label-title">
                      <span className="required">*</span>
                      City
                    </label>
                    <Form.Item
                      name="city"
                      rules={[
                        { whitespace: true },
                        {
                          required: true,
                          message: "Please enter city!",
                        },
                        () => ({
                          validator(_, value) {
                            if (value && value.search(/^[a-zA-Z\s]*$/) === -1) {
                              return Promise.reject("Provide valid name");
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="City Name" />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="label-title">
                      <span className="required">*</span>
                      ZipCode
                    </label>
                    <Form.Item
                      name="zipcode"
                      rules={[
                        { whitespace: true },
                        {
                          required: true,
                          message: "Please enter zipcode!",
                        },
                        () => ({
                          validator(_, value) {
                            if (value && value.length !== 5) {
                              return Promise.reject("Provide valid zipcode");
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input type="number" placeholder="Zipcode" />
                    </Form.Item>
                  </div>
                </div>
              </>
            ) : null}

            <div className="col-12">
              <UploadFile
                accept="image/png, image/jpeg"
                setUserImage={setUserImage}
                userImage={userImage}
              />
            </div>
          </div>
        )}
      </Form>
    </>
  );
};

export default Information;
