import { DatePicker, Form } from 'antd';
import React from 'react'
import moment from 'moment';
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
// import { Form } from "antd"

function Calender() {
  return (
    <>
      <Form.Item
        name="meetingDate"
        rules={[{
          required: true,
          message: 'Please select Date!'
        }]}>
        <DatePicker
          format={dateFormatList}
          disabledDate={(current) => {
            let customDate = moment().format("DD/MM/YY");
            return current && current < moment(customDate, "DD/MM/YY");
          }} />
      </Form.Item>
    </>
  )
}

export default Calender;