import React from 'react';
import './footer.scss';

const Footer = () => {

    return (
        <>
            
        </>
    );
}

export default Footer;