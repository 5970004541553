import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Input,
  Layout,
  Select,
  Switch,
  message,
  Col,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useNavigate } from "react-router-dom";
import "antd/dist/antd.css";
import { API_BASE } from "../../../../../constants/api";
import axios from "axios";
import Breadcrumbs from "../../../../../components/common/Breadcrumb";

const AddService = () => {
  const [form] = Form.useForm();
  const [form1, setForm1] = useState(true);

  const [serviceType, setServiceType] = useState();
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = (values) => {
    const ids = selectedServiceType.map((data, index) => {
      if (
        values["subServiceIncluded" + data._id] &&
        values["subServiceIds" + data._id].length
      ) {
        return {
          id: data._id,
          selectedSubServiceIDS: values["subServiceIds" + data._id],
        };
      } else {
        return {
          id: data._id,
        };
      }
    });
    const taxReturn = values.isTaxReturn
      ? {
          isPersonal: values.personal,
          isBusiness: values.business,
        }
      : { isPersonal: false, isBusiness: false };

    const data = {
      title: values.title,
      isActive: values.isActive,
      selectedServiceTypeIDS: ids,
      availableTime: {
        halfTime: values.halfTime,
        fullTime: values.fullTime,
        adHoc: values.adHoc,
      },
      availableOption: {
        isIndustrySelected: values.isIndustrySelected,
        isSoftwareRequired: values.isSoftwareRequired,
        isTaxReturn: values.isTaxReturn,
        isExpertiseRequired: values.isExpertiseRequired,
      },
      taxReturn,
    };

    axios
      .post(`${API_BASE}v1/service/addService`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success(res.data.message);
          navigate("/data/service");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const data = { limit: 10000 };
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/service/getListOfLinkedServiceType`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setServiceType(
            res.data.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleAbc = (changedFields, allFields) => {
    const newarray = [];
    let id;

    const pattern = /subServiceIncluded/;
    if (pattern.test(changedFields[0].name[0])) setForm1((prev) => !prev);
    else if (changedFields[0].name[0] === "isTaxReturn")
      setForm1((prev) => !prev);
    else if (changedFields[0].name[0] === "serviceType") {
      changedFields[0].value.map((data1, index) => {
        serviceType.map((data2) => {
          if (data1 === data2._id) {
            newarray.push(data2);
          }
        });
      });

      let newarray1 = selectedServiceType.filter((data) => {
        let abc = false;
        newarray.map((data1) => {
          if (data._id === data1._id) {
            abc = true;
          }
        });
        return !abc;
      });

      if (newarray.length === 0) {
        selectedServiceType.map((data) => {
          delete form.getFieldValue()["service" + data._id];
          delete form.getFieldValue()["subServiceIncluded" + data._id];
          delete form.getFieldValue()["subServiceIds" + data._id];
        });
      } else if (newarray.length < selectedServiceType.length) {
        newarray1.map((data) => {
          delete form.getFieldValue()["service" + data._id];
          delete form.getFieldValue()["subServiceIncluded" + data._id];
          delete form.getFieldValue()["subServiceIds" + data._id];
        });
      }

      setSelectedServiceType(newarray);
    }

    // if (selectedServiceType.length === 0) {
    //   newarray.map((data, index) => {
    //     tobject = {
    //       ...tobject,
    //       ["serviceId" + index]: data._id,
    //       ["service" + index]: data.title,
    //       ["subServiceIncluded" + index]: false,
    //       ["subServiceIds" + index]: data.subService,
    //     };
    //   });
    //   //console.log(tobject);
    // } else if (selectedServiceType.length < newarray.length) {
    //   const tarray = newarray.filter((data) => {
    //     let abc = true;
    //     selectedServiceType.map((data1) => {
    //       if (data1._id === data._id) {
    //         abc = false;
    //       }
    //     });
    //     if (abc === false) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   });
    //   console.log(tarray);
    //   tarray.map((data, index) => {
    //     index = selectedServiceType.length;
    //     tobject = {
    //       ...tobject,
    //       ["serviceId" + index]: data._id,
    //       ["service" + index]: data.title,
    //       ["subServiceIncluded" + index]: false,
    //       ["subServiceIds" + index]: data.subService,
    //     };
    //   });
    //   //console.log(tobject);
    // } else {
    //   const tarray = selectedServiceType.filter((data) => {
    //     let abc = false;
    //     newarray.map((data1) => {
    //       if (data1._id === data._id) {
    //         abc = true;
    //       }
    //     });
    //     if (abc) return false;
    //     else return true;
    //   });
    //   console.log(tarray);

    //   for (let t in tobject) {
    //     for (let i = 0; i < selectedServiceType.length; i++) {
    //       if (tobject["serviceId" + i] === tarray[0]._id) {
    //         id = i;
    //       }
    //     }
    //   }
    //   console.log(id);
    //   delete tobject["serviceId" + id];
    //   delete tobject["service" + id];
    //   delete tobject["subServiceIds" + id];
    //   delete tobject["subServiceIncluded" + id];
    // }

    // //delete form.getFieldValue()["service" + id]
    // console.log(tobject);
    // //console.log(form.getFieldValue());
  };

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content="Add New Service" />
          <div className="filter-wrap event-wrap">
            <h2 className="title">Service</h2>
          </div>
          <div className="event-wrap">
            <div className="content-wrap">
              <Form
                onFieldsChange={handleAbc}
                className="sectionadd-form"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">Service Title</label>
                      <Form.Item
                        name="title"
                        rules={[
                          {
                            whitespace: true,
                            message: "name can't be empty!",
                          },
                          {
                            required: true,
                            message: "Please enter service name!",
                          },
                          () => ({
                            validator(_, value) {
                              // if (value && value.search(/^[a-zA-Z]+$/) === -1) {
                              //   return Promise.reject("Provide valid name");
                              // }
                              if (
                                value &&
                                (value.length < 2 || value.length > 50)
                              ) {
                                return Promise.reject(
                                  "name must contain at least 2 character"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Service Title" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">
                        Status (Inactive/Active)
                      </label>
                      <Form.Item
                        initialValue={true}
                        name="isActive"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">Service Type</label>
                      <Form.Item name="serviceType">
                        <Select
                          size="large"
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Please select"
                        >
                          {serviceType?.map((data) => (
                            <Select.Option key={data._id} value={data._id}>
                              {data.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                {selectedServiceType?.map((data, index) => (
                  <div className="row">
                    {/* <Col span={4}>
                      <p>Service Type Name:</p>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={"service" + data._id}
                        initialValue={data.title}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                         */}
                    <div className="col-2">
                      <p>Service Type Name:</p>
                    </div>
                    <div className="col-2">
                      <Form.Item
                        name={"service" + data._id}
                        initialValue={data.title}
                      >
                        <Input disabled />
                      </Form.Item>
                    </div>

                    {/* <Col span={4}>
                      <p>Include Sub Service? </p>
                    </Col>  
                    <Col span={4}>
                      <Form.Item
                        initialValue={false}
                        name={"subServiceIncluded" + data._id}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col> */}
                    <div className="col-2">
                      <p>Include Sub Service ?</p>
                    </div>
                    <div className="col-1">
                      <Form.Item
                        initialValue={false}
                        name={"subServiceIncluded" + data._id}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>

                    {form.getFieldValue()["subServiceIncluded" + data._id] ? (
                      <>
                        <div className="col-2">
                          <label className="label-title">
                            Sub Service Type
                          </label>
                        </div>

                        <div className="col-3">
                          <Form.Item
                            name={"subServiceIds" + data._id}
                            initialValue={[]}
                            rules={[
                              {
                                required: data.subService.length ? true : false,
                                message: "Please select sub service!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Please select sub service"
                              className="multi-box"
                            >
                              {data.subService?.map((data) => (
                                <Select.Option key={data._id} value={data._id}>
                                  {data.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}

                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <h5>HT/FT/AdHoc</h5>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="label-title">HT</label>
                          <Form.Item
                            initialValue={false}
                            name="halfTime"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="label-title">FT</label>
                          <Form.Item
                            initialValue={false}
                            name="fullTime"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="label-title">AdHoc</label>
                          <Form.Item
                            initialValue={false}
                            name="adHoc"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>

                  {form.getFieldValue().isTaxReturn ? (
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <h5>Tax Return</h5>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="label-title">Personal</label>
                            <Form.Item
                              initialValue={false}
                              name="personal"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="label-title">Business</label>
                            <Form.Item
                              initialValue={false}
                              name="business"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">
                        Industry Selection Option
                      </label>
                      <Form.Item
                        initialValue={false}
                        name="isIndustrySelected"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">
                        Select Software Required
                      </label>
                      <Form.Item
                        initialValue={false}
                        name="isSoftwareRequired"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">
                        Expertise Required Option
                      </label>
                      <Form.Item
                        initialValue={false}
                        name="isExpertiseRequired"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">Tax Retrun</label>
                      <Form.Item
                        initialValue={false}
                        name="isTaxReturn"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-4">
                    <div className="view-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary"
                      >
                        Save
                      </Button>
                      <Button type="secondary" className="btn-secondary">
                        <Link to="/data/service">Cancel</Link>
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>{" "}
        </Content>
      </Layout>
    </>
  );
};

export default AddService;
