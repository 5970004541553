import React, { useEffect, useState } from "react";
import { Button, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./excel.scss";
import { useDispatch } from "react-redux";
import { uploadBulkUser } from "../../../Redux/userSlice";
import axios from "axios";
import { API_BASE } from "../../../constants/api";

const MExcel = ({ setexcelInfoVisible, excelInfoVisible }) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [error, setError] = useState();

  const dispatch = useDispatch();

  const handleFile = ({ file, fileList }) => {
    //console.log(file, fileList);
    setFile(fileList);
  };

  const showexcelInfo = () => {
    setFile();
    setFileName();
    setexcelInfoVisible(false);
  };

  useEffect(() => {
    const data = { fileName };
    //console.log(fileName);
    if (fileName) {
      dispatch(uploadBulkUser(data))
        .then((res) => {
          if (res.payload.statusCode === 200) {
            //console.log(res.payload);
            message.success(res.payload.message);
            setFile();
            setFileName();
            setexcelInfoVisible(false);
          } else {
            message.error(res.payload.message);
            setFile();
            setFileName();
            setexcelInfoVisible(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setFile();
          setFileName();
          setexcelInfoVisible(false);
        });
    }
  }, [fileName]);

  const handleUpload = () => {
    if (file.length === 0) {
      setError("Select file to upload");
      return;
    }
    setError("");

    let formData = new FormData();
    formData.append("file", file[0].originFileObj);
    formData.append("moduleName", "excell");

    //console.log(file[0]);
    axios
      .post(`${API_BASE}local-common/uploadFile`, formData)
      .then((res) => {
        //console.log(res.data);
        if (res.data.statusCode === 200) {
          //console.log(res.data.name);
          setFile();
          setFileName(res.data.data.name);
        } else {
          setFile();
          setFileName();
          setexcelInfoVisible(false);
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        //message.error(res.payload.message);
        console.log(err);
        setFile();
        setFileName();
        setexcelInfoVisible(false);
      });
  };

  const handleCancel = () => {
    setFile();
    setFileName();
    setexcelInfoVisible(false);
  };

  return (
    <>
      <Modal
        //afterClose={handleCancel}
        //destroyOnClose={true}
        visible={excelInfoVisible}
        //onOk={handleExcelUpload}
        onCancel={handleCancel}
        centered={true}
        className="common-modal edit-modal"
        footer={[
          <Button className="cancle-btn" onClick={showexcelInfo}>
            Cancel
          </Button>,
          <Button className="submit-btn" onClick={handleUpload}>
            Submit
          </Button>,
        ]}
      >
        <div className="modal-body">
          <h4>Bulk upload BD User</h4>
          <label>Upload Excel File : </label>
          <Upload
            beforeUpload={() => {
              return false;
            }}
            openFileDialogOnClick="false"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            //action={handleUpload}
            onChange={handleFile}
            visible="false"
          >
            <Button
              icon={<UploadOutlined />}
              disabled={file?.length === 1 ? true : false}
            >
              Upload
            </Button>
          </Upload>
          <p style={{ color: "red" }}>{error}</p>
        </div>
      </Modal>
    </>
  );
};
export default MExcel;
