import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_FORGOT_PASSWORD, API_LOGIN } from "../constants/api";

const initialState = {
  user: {},
  forgotPasswordResponse: {},
  isLoggedIn: false,
};

export const loginHandler = createAsyncThunk(
  "login/handleLogin",
  async (credentials) => {
    try {
      const response = await axios.post(API_LOGIN, credentials);
      //console.log(response);
      if (response.data.statusCode === 200) {
        axios.defaults.headers.common["Authorization"] = response.data.token;
        localStorage.setItem("userType", response.data.data.userType);
        localStorage.setItem(
          "isPasswordChanged",
          response.data.data.isPasswordChange
        );
        localStorage.setItem("rememberMe", credentials.remember);
        localStorage.setItem("access_token", response.data.token);
        // localStorage.setItem("userID", response.data.data._id);
        localStorage.setItem("user", response.data.data._id);
        // localStorage.setItem("clientID", response?.data?.data?.clientID);
        localStorage.setItem(
          "userName",
          response.data.data.firstName + " " + response.data.data.lastName
        );
      }
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const forgotPasswordHandler = createAsyncThunk(
  "login/handleForgotPassword",
  async (credentials) => {
    try {
      const response = await axios.post(API_FORGOT_PASSWORD, credentials);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(loginHandler.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.isLoggedIn = true;
    });
    builder.addCase(forgotPasswordHandler.fulfilled, (state, action) => {
      state.forgotPasswordResponse = action.payload;
      state.isLoggedIn = false;
    });
  },
});

export default loginSlice.reducer;
