import WithLayout from "../../components/layout";
import STORAGE_KEYS from "../../keys";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

let AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  let remember = localStorage.getItem("rememberMe");
  let isPasswordChanged = localStorage.getItem("isPasswordChanged");

  const isLoggedIn = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    ? localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    : document?.cookie?.split?.(";")?.[0]?.split?.("=")?.[1];

  useEffect(() => {
    if (isPasswordChanged === "false") navigate("/set-password/false");
    if (remember === "false") localStorage.clear();
    if (!isLoggedIn) navigate("/login");
  }, [isLoggedIn, navigate]);

  return <WithLayout>{children}</WithLayout>;
};

export default AuthGuard;
