import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  fileList: {},
};

export const uploadFile = createAsyncThunk(
  "upload/uploadFile",
  async (formData) => {
    try {
      const response = await axios.post(
        `${API_BASE}common/S3upload`,
        formData
      );
      //console.log(response);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      //state.user = action.payload.data;
      //state.isLoggedIn = true;
    });
  },
});

export default uploadSlice.reducer;
