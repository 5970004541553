import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {};

export const getDashboardDetails = createAsyncThunk(
    "dashboard/getDashboardDetails",
    async (data) => {
        try {
            const response = await axios.post(
                `${API_BASE}v1/dashboard/getDashboardDetails`,
                data
            );
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    }
);

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},

    extraReducers: (builder) => {

    },
});

export default dashboardSlice.reducer;
