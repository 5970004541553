import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  subServiceTypes: [],
  subServiceType: {},
};

export const addSubServiceType = createAsyncThunk(
  "subServiceType/addSubServiceType",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/sub-service-type/addSubServiceType`,
        data
      );
      //console.log("response.data.data.message: ", response.data.message);
      return response.data.message;
    } catch (err) {
      return err.response.data.message;
    }
  }
);

export const getAllSubServiceType = createAsyncThunk(
  "subServiceType/getAllSubServiceType",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE}v1/sub-service-type/getAllSubServiceType`
      );
      return response.data.data.subServiceTypeList;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getSubServiceTypeById = createAsyncThunk(
  "subServiceType/getSubServiceTypeById",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE}v1/sub-service-type/getSubServiceTypeById/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateSubServiceType = createAsyncThunk(
  "subServiceType/updateSubServiceType",
  async (id) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/sub-service-type/updateSubServiceType/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const deleteSubServiceType = createAsyncThunk(
  "subServiceType/deleteSubServiceType",
  async (id) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/sub-service-type/deleteSubServiceType/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const subServiceTypeSlice = createSlice({
  name: "subServiceType",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addSubServiceType.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(getAllSubServiceType.fulfilled, (state, action) => {
      //console.log("action.payload: ", action.payload);
      state.subServiceTypes = action.payload;
    });
    builder.addCase(getSubServiceTypeById.fulfilled, (state, action) => {
      state.subServiceType = action.payload.data;
      return action.payload;
    });
    builder.addCase(updateSubServiceType.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(deleteSubServiceType.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default subServiceTypeSlice.reducer;
