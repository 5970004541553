import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  serviceTypes: [],
  serviceType: {},
};

export const addServiceType = createAsyncThunk(
  "serviceType/addServiceType",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/service-type/addServiceType`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getAllServiceType = createAsyncThunk(
  "serviceType/getAllServiceType",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE}v1/service-type/getServiceTypeList`
      );
      return response.data.data.serviceTypeList;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getServiceTypeById = createAsyncThunk(
  "serviceType/getServiceTypeById",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE}v1/service-type/getServiceTypeById/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateServiceType = createAsyncThunk(
  "serviceType/updateServiceType",
  async (id) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/service-type/updateServiceType/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const deleteServiceType = createAsyncThunk(
  "serviceType/deleteServiceType",
  async (id) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/service-type/deleteServiceType/${id}`
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const serviceTypeSlice = createSlice({
  name: "serviceType",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addServiceType.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(getAllServiceType.fulfilled, (state, action) => {
      state.serviceTypes = action.payload;
      //console.log("action.payload: ", action.payload);
    });
    builder.addCase(getServiceTypeById.fulfilled, (state, action) => {
      state.serviceType = action.payload.data;
      return action.payload;
    });
    builder.addCase(updateServiceType.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(deleteServiceType.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default serviceTypeSlice.reducer;
