import {
  Breadcrumb,
  Button,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  deleteMeeting,
  getListOfMeeting,
} from "../../../../Redux/scheduleMeeting";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";

function ViewMeeting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ModalVisible, setModalVisible] = useState(false);

  const [users, setUsers] = useState();
  const [totalUsers, setTotalUsers] = useState();
  // const [columns, setColumns] = useState();

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState();
  const [reload, setReload] = useState(false);

  // table pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
  });

  const columns = [
    {
      title: "Date",
      // dataIndex: "meetingDate",
      key: "meetingDate",
      render: (row) =>
        moment(row?.meetingDate).tz("America/New_York").format("DD-MM-YYYY"),
    },
    {
      title: "EST",
      // dataIndex: "est",
      key: "est",
      render: (row) =>
        moment(row?.meetingDate).tz("America/New_York").format("hh:mm a"),
    },
    {
      title: "NAME",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Action",
      // dataIndex: "action",
      key: "action",
      className: "view",
      render: (row, record) => (
        <ul className="action-col">
          <li>
            <EditTwoTone
              onClick={() =>
                navigate(`/schedule_meeting/view_meeting/${row._id}`)
              }
            />
          </li>
          <li>
            <DeleteTwoTone
              onClick={() => {
                setModalVisible(true);
                setId(row?._id);
              }}
            />
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getListOfMeeting({
        limit: limit,
        skip: skip,
        search: search,
      })
    )
      .then((res) => {
        //console.log(res.payload.data);
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setUsers(
            res.payload.data.list.map((data, index) => {
              return { ...data, key: index };
            })
          );
          setTotalUsers(res.payload.data.total_records);
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [limit, skip, search, reload]);
  console.log(id);

  const handleOk = () => {
    setIsLoading(true);
    dispatch(deleteMeeting(id))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          setModalVisible(false);
          setReload(!reload);
        } else {
          message.error(res.payload.message);
          setModalVisible(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setModalVisible(false);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumb />

          <div className="filter-wrap event-wrap">
            <h2 className="title">View Meeting Schedule</h2>
            <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="/schedule_meeting">Back</Link>
              </Button>
            </Row>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <div className="content-wrap">
                <Row className="search" justify="end">
                  <div className="col-lg-3 col-sm-4">
                    <Input
                      placeholder="Search here"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </Row>
                <div className="common-table mt-15">
                  <h3>Meeting History</h3>
                  <Table
                    bordered
                    columns={columns}
                    Loading={isLoading}
                    dataSource={users}
                    scroll={{ x: 870 }}
                    pagination={{
                      current: page,
                      defaultPageSize: limit,
                      total: totalUsers,
                      showTotal: (total) => `Total ${total} records`,
                      onChange: (page, pagesize) => {
                        setPage(page);
                        setSkip((page - 1) * pagesize);
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {ModalVisible ? (
            <Modal
              visible={ModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              centered={true}
              className="common-modal edit-modal"
            >
              <div className="modal-body">
                <div className="desc">
                  {/* <span className="icon"><ExclamationCircleOutlined /></span> */}
                  <h2 className="title">
                    Are you sure you want to delete meeting ?
                  </h2>
                  <p>Your action can't be undone</p>
                </div>
              </div>
            </Modal>
          ) : null}
        </Content>
      </Layout>
    </>
  );
}

export default ViewMeeting;
