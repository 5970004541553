import React, { useEffect, useState } from "react";
import { Button, Form, Input, Layout, Switch, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateMail, getMailDetails } from "../../../../Redux/emailSlice";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";

const EditTemp = () => {
  const id = useParams();
  const [form] = Form.useForm();
  const [status, setStatus] = useState(true);
  const [text, settext] = useState();    
  const [emailData, setEmailData] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
                            
  const onFinish = (values) => {
    const newdata = { ...values, mailTemplateId: id?.id };
    dispatch(updateMail(newdata))                     
      .then((res) => {    
        if (res.payload.statusCode === 200) {         
          message.success(res.payload.message);
          navigate("/email");
        } else {
          message.error(res.payload.message);
        }                  
      })
      .catch((err) => {                    
        console.log(err);            
      });         
  };         
                                                  
  //Quill cms    
  function handleChange(value) {
    settext(value);
  }
  useEffect(() => {
    dispatch(getMailDetails(id?.id))
      .then((res) => {
        if (res.payload.statusCode == 200) {
          setEmailData(res.payload.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, [id?.id]);
  useEffect(() => {
    form.setFieldsValue({
      subject: emailData?.subject,
      description: emailData?.description,
    });
    settext(emailData?.description);
  }, [emailData, form]);
                                                                                                                                                                              
  return (
    <>    
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content="Add Manager" />

          <div className="filter-wrap event-wrap">
            <h2 className="title">Edit Email Template</h2>
          </div>
              
          <div className="event-wrap">
            <div className="content-wrap">
              <Form
                className="sectionadd-form"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label-title">Subject</label>
                      <Form.Item
                        name="subject"
                        rules={[                
                          {
                            required: true,
                            message: "Please enter Subject!",
                          },
                        ]}
                      >
                        <Input placeholder="Subject" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="label-title">Body</label>
                    {text && (
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Body!",
                          },
                        ]}
                      >
                        <ReactQuill value={text} onChange={handleChange} />
                      </Form.Item>
                    )}
                  </div>                                                    
                  <div className="col-12 mt-4">
                    <div className="view-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary"
                      >
                        Save                  
                      </Button>                                                    
                      <Button
                        type="secondary"
                        htmlType="button"  
                        className="btn-secondary"
                      >           
                        <Link to="/email">Cancel</Link>    
                      </Button>
                    </div>                    
                  </div>                                              
                </div>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};
    
export default EditTemp;
