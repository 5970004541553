import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Upload, message, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../../constants/api";

const Invoice = (prop) => {
  const [form] = Form.useForm();
  
  const [invoiceFile, setInvoiceFile] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const baseURL = "https://clienton-boarding.s3.amazonaws.com/invoice/"

  useEffect(() => {
    let clientid = localStorage.getItem("clientID");
    setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/getInvoiceDetails`, {clientID :clientid})
      .then((res) => {
        setIsLoading(false);
        setInvoiceData(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (invoiceData) {
      form.setFieldsValue({
        invoiceGeneratedDate: moment(invoiceData.invoiceGeneratedDate),
        invoiceSentDate: moment(invoiceData.invoiceSentDate),
        paymentReceivedDate: moment(invoiceData.paymentReceivedDate),
      });
      setInvoiceFile(invoiceData.invoicePDF);
    }
  }, [invoiceData]);

  const onFinish = (values) => {
    if (!invoiceFile) {
      setError("Please Select Invoice File");
      return;
    }
    setError("");

    let clientid = localStorage.getItem("clientID");
    
    let newData = {
      clientID : clientid,
      invoiceGeneratedDate : moment(values.invoiceGeneratedDate).toISOString(),
      invoiceSentDate : moment(values.invoiceSentDate).toISOString(),
      paymentReceivedDate : moment(values.paymentReceivedDate).toISOString(),
      invoicePDF : invoiceFile
    }
    console.log(newData)
    
    if(!invoiceData){
      setIsLoading(true);
      axios
        .post(`${API_BASE}v1/clientForm/addInvoice`, newData)
        .then((res) => {        
          setIsLoading(false);
          if (res.data.statusCode === 200) {          
            message.success(res.data.message);
            prop.setCurrent((prev) => prev + 1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(true);
    axios
      .post(`${API_BASE}v1/clientForm/updateInvoice`, {...newData, invoice_Id :invoiceData._id})
      .then((res) => {        
        setIsLoading(false);
        if (res.data.statusCode === 200) {          
          message.success(res.data.message);
          prop.setCurrent((prev) => prev + 1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    }
    
  };

  const props = {
    maxCount: 1,
    accept: ".pdf",
    beforeUpload: () => {
      return false;
    },
    onChange(info) {    
      if (info.fileList.length) {
        setError("");
        let formData = new FormData();
        formData.append("image", info.file);
        formData.append("moduleName", "invoice");

        setIsLoading(true);
        axios
          .post(`${API_BASE}common/S3upload`, formData)
          .then((res) => {
            setIsLoading(false);
            //console.log(res.data.data)
            setInvoiceFile(res.data.data.name)
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
        }
      else {
        setError("Please Select Invoice File");
        setInvoiceFile("");
      }  
    },
    onRemove(file) {
      setInvoiceFile("");
      setError("Please Select Invoice File");
    },
  };

  return (
    <Form
      ref={prop.refs}
      form={form}
      name="basic12"
      onFinish={onFinish}
      autoComplete="off"
    >
      {isLoading ? <Spin/> :
      <>
      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Add Invoice Generation Date
              </label>
              <Form.Item
                name="invoiceGeneratedDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select Option!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Add Invoice Sent Date
              </label>
              <Form.Item
                name="invoiceSentDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select Option!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Add Payment Received Date
              </label>
              <Form.Item
                name="paymentReceivedDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select Option!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="service-section job-section">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="label-title">
                <span className="required">*</span>
                Upload Invoice PDF
              </label>
              <Form.Item name="invoice" className="upload-changepdf">
              <Upload
                {...props}
                fileList={
                  invoiceFile
                    ? [
                        {
                          uid: "1",
                          name: "Invoice",    
                          url: baseURL + invoiceFile,                          
                        },
                      ]
                    : []
                }
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
              </Form.Item>
            </div>
            <div style={{ color: "red" }}>{error}</div>
          </div>
        </div>
      </div>
      </>
      }
    </Form>
  );
};

export default Invoice;
