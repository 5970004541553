import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../constants/api";

const initialState = {
  expertiseList: {},
};

export const getExpertiseList = createAsyncThunk(
  "expertise/getExpertiseList",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE}v1/expertise/getExpertiseList`,
        data
      );
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const expertiseSlice = createSlice({
  name: "expertise",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getExpertiseList.fulfilled, (state, action) => {
      //state.user = action.payload.data;
      //state.isLoggedIn = true;
    });
  },
});

export default expertiseSlice.reducer;
