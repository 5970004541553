import { Breadcrumb, Button, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CommonMeeting from "../../../components/common/CommonMeet";
import { getListOfUpcommingMeeting } from "../../../Redux/scheduleMeeting";
import { getListUsers } from "../../../Redux/userSlice";

const Meeting = () => {
  const dispatch = useDispatch();
  const [upcomingMeeting, setUpcomingMeeting] = useState();
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 200,
  // });
  const [clientList, setClientList] = useState([]);
  const [excelInfoVisible, setexcelInfoVisible] = useState(false);

  useEffect(() => {
    dispatch(getListUsers({ userType: [2] }))
      .then((res) => {
        setClientList(res.payload.data.list);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    dispatch(
      getListOfUpcommingMeeting({
        // "clientID": meetingData?.clientID,
        // startDate: moment().startOf('week').format("YYYY-MM-DD"),
        // endDate: moment().endOf('week').format("YYYY-MM-DD")
      })
    )
      .then((res) => {
        if (res.payload.statusCode == 200) {
          setUpcomingMeeting(res.payload.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);
  
  const note = () => {
    setexcelInfoVisible(true);
  };

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumb />

          <div className="filter-wrap event-wrap">
            <h2 className="title">Meeting Schedule</h2>
            <Row justify="end" className="btn-box">
              <Button type="primary" htmlType="submit" className="btn-primary">
                <Link to="/schedule_meeting/view_meeting">View History</Link>
              </Button>
              <Button className="btn-primary" type="primary" onClick={note}>
                    Add Note
              </Button>
            </Row>
          </div>

          <div className="remove-wrap">
            <div className="event-wrap">
              <CommonMeeting
                clientList={clientList}
                type="add"
                umdata={upcomingMeeting}
                excelInfoVisible={excelInfoVisible}
                setexcelInfoVisible={setexcelInfoVisible}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Meeting;
