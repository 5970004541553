import { Button, Form, Input, Layout, message, Row, Spin, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../../components/common/Breadcrumb";
import { API_BASE } from "../../../../../constants/api";

function AddServiceType() {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = (values) => {
    axios
      .post(`${API_BASE}v1/sub-service-type/addSubServiceType`, values)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success(res.data.message);
          navigate("/data/sub_service");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Layout className="site-layout-background">
          <Content className="admin-webpanel-content">
            <Breadcrumbs content="Add Sub Service Type" />

            <div className="filter-wrap  event-wrap">
              <div>
                <h2 className="title">Sub Service Type</h2>
              </div>
            </div>

            <div className="remove-wrap">
              <div className="event-wrap">
                <Form
                  className="sectionadd-form"
                  form={form}
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    isActive: true,
                  }}
                >
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">
                          Sub Service Type Name
                        </label>
                        <Form.Item
                          name="title"
                          rules={[
                            {
                              whitespace: true,
                              message: "name can't be empty!",
                            },
                            {
                              required: true,
                              message: "Please enter sub service type name!",
                            },
                            () => ({
                              validator(_, value) {
                                // if (value && value.search(/^[a-zA-Z]+$/) === -1) {
                                //   return Promise.reject("Provide valid name");
                                // }
                                if (
                                  value &&
                                  (value.length < 2 || value.length > 50)
                                ) {
                                  return Promise.reject(
                                    "name must contain at least 2 character"
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Sub Service Type Name" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="label-title">
                          Status (Inactive/Active)
                        </label>
                        <Form.Item name="isActive" valuePropName="checked">
                          <Switch />
                        </Form.Item>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12 mt-4">
                      <div className="view-btn">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-primary"
                        >
                          Save
                        </Button>
                        <Button
                          type="secondary"
                          htmlType="submit"
                          className="btn-secondary"
                        >
                          <Link to="/data/sub_service">Cancel</Link>
                        </Button>
                      </div>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </Content>
        </Layout>
      )}
    </>
  );
}

export default AddServiceType;
