import { Breadcrumb } from "antd";
import React from "react";

const Breadcrumbs = ({ content }) => {
  return (
    <>
      {/* <div className="breadcrumb-wrap">
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>{content}</Breadcrumb.Item>
        </Breadcrumb>
        <h2 className="title">BD User</h2>
      </div> */}
    </>
  );
};

export default Breadcrumbs;
