import React, { useState } from "react";
import { Button, Form, Input, Layout, Switch, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../../../Redux/userSlice";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import { addFaq } from "../../../../Redux/faqSlice";

const AddFaq = () => {
  const [form] = Form.useForm();
  const [status, setStatus] = useState(true);
  const [text, settext] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    dispatch(addFaq(values))
      .then((res) => {
        if (res.payload.statusCode === 200) {
          message.success(res.payload.message);
          navigate("/faq");
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Quill cms

  function handleChange(value) {
    settext(value);
  }

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content="Add Manager" />

          <div className="filter-wrap event-wrap">
            <h2 className="title">Add FAQ</h2>
          </div>

          <div className="event-wrap">
            <div className="content-wrap">
              <Form
                className="sectionadd-form"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label-title">Question</label>
                      <Form.Item
                        name="question"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Question!",
                          },
                        ]}
                      >
                        <Input placeholder="Question" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12">
                    <Form.Item
                      name="answer"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Answer!",
                        },
                      ]}
                    >
                      <ReactQuill value={text} onChange={handleChange} />
                    </Form.Item>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="view-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary"
                      >
                        Save
                      </Button>
                      <Button
                        type="secondary"
                        htmlType="button"
                        className="btn-secondary"
                      >
                        <Link to="/faq">Cancel</Link>
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AddFaq;
