import React, { useEffect, useState } from "react";
import { Button, Form, Layout, message, Select, InputNumber, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllService } from "../../../../Redux/serviceSlice";
import axios from "axios";
import { API_BASE } from "../../../../constants/api";

const AddPakages = (prop) => {
  const [form] = Form.useForm();
  const [pageType, setPageType] = useState();

  const [size, setSize] = useState("middle");
  const [isLoading, setIsLoading] = useState(false);

  const [package1, setPackage1] = useState();
  const [serviceList, setServiceList] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageType(prop.pageType);
  }, [prop]);

  //console.log(location.state.id);
  useEffect(() => {
    if (location?.state?.id) {
      setIsLoading(true);
      axios
        .get(`${API_BASE}v1/package/getPackageById/${location?.state?.id}`)
        .then((res) => {
          setIsLoading(false);

          if (res.data.statusCode === 200) {
            setPackage1(res.data.data);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);
                                            
  //setting Form
  useEffect(() => {
    if (package1) {
      form.setFieldsValue({
        selectedService: package1?.selectedService,
        stateReturn: package1?.stateReturn,
        ftAmount: package1?.fullTimeRate?.inputAmount,
        ftCurrency: package1?.fullTimeRate?.currency,
        ftHours: package1?.fullTimeRate?.hours,
        htAmount: package1?.halfTimeRate?.inputAmount,
        htCurrency: package1?.halfTimeRate?.currency,
        htHours: package1?.halfTimeRate?.hours,
        adCurrency: package1?.adHocRate?.currency,
        adAmount: package1?.adHocRate?.amount,
        adHours: package1?.adHocRate?.hours,
        min: package1?.noOfReturn?.min,
        max: package1?.noOfReturn?.max,
      });
    }
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllService())
      .then((res) => {
        //console.log(res.payload);
        setIsLoading(false);
        if (res.payload.statusCode === 200) {
          setServiceList(
            res.payload.data.serviceList.map((data, index) => {
              return { key: index, id: data._id, title: data.title };
            })
          );
        } else {
          message.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const onFinish = (values) => {
    const newdata = {
      selectedService: values.selectedService,
      halfTimeRate: {
        currency: values.htCurrency,
        amount: values.htAmount,
        hours: values.htHours,
        inputAmount: values.htAmount,
      },
      fullTimeRate: {
        currency: values.ftCurrency,
        amount: values.ftAmount,
        hours: values.ftHours,
        inputAmount: values.ftAmount,
      },
      adHocRate: {
        currency: values.adCurrency,
        amount: values.adAmount,
        hours: values.adHours,
      },
      //isReviewByManager: true,
      stateReturn: values.stateReturn,
      noOfReturn: {
        min: values.min,
        max: values.max,
      },
    };

    if (!package1) {
      axios
        .post(`${API_BASE}v1/package/addPackage`, newdata)
        .then((res) => {
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            message.success(res.data.message);
            navigate("/manage_packge");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      axios
        .post(`${API_BASE}v1/package/updatePackage/${location?.state?.id}`, {
          ...newdata,
          packageId: location?.state?.id,
        })
        .then((res) => {
          setIsLoading(false);
          if (res.data.statusCode === 200) {
            message.success(res.data.message);
            navigate("/manage_packge");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Layout className="site-layout-background">
        <Content className="admin-webpanel-content">
          <Breadcrumbs content="Add Manager" />

          <div className="filter-wrap event-wrap">
            <h2 className="title">{pageType} Package</h2>
          </div>

          <div className="event-wrap">
            <div className="content-wrap">
              <Form
                className="sectionadd-form"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="label-title">Select Service</label>
                      <Form.Item
                        name="selectedService"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Service!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          style={{
                            width: "100%",
                          }}
                          placeholder="Please select"
                        >
                          {serviceList &&
                            serviceList?.map((data) => (
                              <Select.Option key={data.id} value={data.id}>
                                {data.title}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="label-title">
                        Rate for engagement and model
                      </label>
                      <Row align="middle" wrap={false} className="row-area-box">
                        <label className="label-title">Full Time </label>
                        <Form.Item
                          name="ftCurrency"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <Select
                            size={size}
                            placeholder="Currency"
                            //onChange={handleChange}
                            style={{
                              width: 100,
                              marginRight: 10,
                            }}
                          >
                            <Select.Option key={1} value="USD">
                              USD
                            </Select.Option>
                            <Select.Option key={2} value="AUD">
                              AUD
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="ftAmount"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            style={{
                              width: 125,
                              marginRight: 10,
                            }}
                            placeholder="Rate per week"
                          />
                        </Form.Item>
                        <Form.Item
                          name="ftHours"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            placeholder="Hours per week"
                          />
                        </Form.Item>
                      </Row>
                      <Row align="middle" wrap={false} className="row-area-box">
                        <label className="label-title">Half Time </label>
                        <Form.Item
                          name="htCurrency"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <Select
                            size={size}
                            placeholder="Currency"
                            //onChange={handleChange}
                            style={{
                              width: 100,
                              marginRight: 10,
                            }}
                          >
                            <Select.Option key={1} value="USD">
                              USD
                            </Select.Option>
                            <Select.Option key={2} value="AUD">
                              AUD
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="htAmount"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            style={{
                              width: 125,
                              marginRight: 10,
                            }}
                            placeholder="Rate per week"
                          />
                        </Form.Item>
                        <Form.Item
                          name="htHours"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            placeholder="Hours per week"
                          />
                        </Form.Item>
                      </Row>

                      <Row align="middle" wrap={false} className="row-area-box">
                        <label className="label-title">AdHoc </label>
                        <Form.Item
                          name="adCurrency"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <Select
                            size={size}
                            placeholder="Currency"
                            //onChange={handleChange}
                            style={{
                              width: 100,
                              marginRight: 10,
                            }}
                          >
                            <Select.Option key={1} value="USD">
                              USD
                            </Select.Option>
                            <Select.Option key={2} value="AUD">
                              AUD
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="adAmount"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            style={{
                              width: 220,
                              marginRight: 10,
                            }}
                            placeholder="Amount Per Hour"
                          />
                        </Form.Item>
                        {/* <Form.Item
                          name="adHours"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >    
                          <InputNumber
                            className="inputs-number-boxs"
                            placeholder="Hours"
                          />
                        </Form.Item> */}
                      </Row>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="label-title">State Returns</label>
                      <Form.Item
                        name="stateReturn"
                        // rules={[
                        //       {
                        //     required: true,
                        //     message: "Please Select Option!",
                        //   },
                        // ]}
                      >
                        <InputNumber
                          addonBefore="States"
                          className="inputs-number-boxs"
                          placeholder="State Return"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="label-title">Number Of Returns</label>
                      <Row align="middle" wrap={false} className="row-area-box">
                        <Form.Item
                          name="min"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            placeholder="Min"
                          />
                        </Form.Item>
                        <span style={{ margin: "0 10px" }}>-</span>
                        <Form.Item
                          name="max"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select Option!",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            className="inputs-number-boxs"
                            placeholder="Max"
                          />
                        </Form.Item>
                      </Row>
                    </div>
                  </div>

                  <div className="col-12 mt-4">
                    <div className="view-btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary"
                      >
                        Save
                      </Button>
                      <Button
                        type="secondary"
                        htmlType="button"
                        className="btn-secondary"
                      >
                        <Link to="/manage_packge">Cancel</Link>
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AddPakages;
